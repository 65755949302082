import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartyModel } from '../../../../core/models/party/party.model';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import { Subscription, take } from 'rxjs';
import { PartyService } from '../../../../core/services/api/company/party.service';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { ToastrService } from 'ngx-toastr';
import { FapModalComponent } from '../../../../shared/partials';
import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import { ResponseModel } from '../../../../core/models/response.model';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { MatSelect } from '@angular/material/select';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
  selector: 'fap-add-edit-product-label',
  templateUrl: './fap-add-edit-product-label.component.html',
  styleUrls: ['./fap-add-edit-product-label.component.scss']
})
export class FapAddEditProductLabelComponent implements OnInit, OnChanges {

  @Input() public labelId = -1;
  @Input() public from;
  
  @Output()
  public closeModal: EventEmitter<any> = new EventEmitter();
  @Output()
  public addedLabel: EventEmitter<any> = new EventEmitter();
  @Output()
  public updatedLabel: EventEmitter<any> = new EventEmitter();
  
  public label = null;
  public produced = false;
  public productLabelForm: FormGroup;
  public localIngredients: Array<IngredientModel> = [];
  public localIngredientsId: Array<any> = [];
  public defaultParty: PartyModel;
  public parties: Array<PartyModel> = [];
  public selectedParty: PartyModel;
  public selectedIngredient: IngredientModel = null;
  public ingredientTypeMap: { [key: number]: IngredientTypeModel } = {};
  public getMoreParties = true;
  public partyToken: { limit: number; offset: number; search: string; } = null;
  public getMore = true;
  public selectedResource: ResourceTypeModel;
  public parentResource: ResourceTypeModel;
  public resourceTypeId: any;
  public resourceType: any;
  public limit = 20;
  public searchKeyword = '';
  public entityInfo: any;
  public descriptions = [];

  public subscription: Subscription = new Subscription();

  @ViewChild('addResourceTypeModal')
  public addResourceTypeModal: FapModalComponent;
  @ViewChild('addEditPartyModal')
  public addEditPartyModal: FapModalComponent;
  @ViewChild('addEditIngredientModal')
  public addEditIngredientModal: FapModalComponent;
  @ViewChild('rsp') public rsp;
  @ViewChild('ingredientSelect') ingredientSelect: MatSelect;
  public langString: string;

  constructor(private formBuilder: FormBuilder, private partyService: PartyService, private translate: TranslateService, public globalRegistry: GlobalRegistryService, private toastr: ToastrService, private cacheService: CacheResolverService, private stockService: StockService, private typesService: TypesService, private navService: NavService) {
  }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'produced') && this.produced) {
      console.log(this.produced);
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'from') && this.from) {
      console.log(this.from);
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'label') && this.label) {
      this.initForm();
      if(this.label && this.label.description.length) {
        this.descriptions = this.label.description;
      }
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'labelId') && this.labelId) {
      console.log(changes);
      console.log(this.labelId);
      // this.entityInfo = null;
      if(this.labelId == -1) {
        this.resetForm(this.productLabelForm);
        this.labelId = null;
        this.label = null;
        this.localIngredients = [];
        this.descriptions = [];
        this.productLabelForm.get('ingredients').setValue([]);
        this.localIngredientsId = [];
        this.produced = false;
        this.productLabelForm.get('produced').setValue(0);
        if(this.from) {
          this.productLabelForm.get('produced').setValue(1);
          this.produced = true;
        }
      } else {
        this.getProductLabel(this.labelId);
      }
    }
  }

  getProductLabel(labelId) {
    this.stockService.getProductLabel(labelId).subscribe(data => {
      console.log(data);
      this.label = data.body.results;
      this.initForm();
    })
  }

  getInfoSection(infoSectionId: number) {
    console.log(infoSectionId);
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
  }

  public ifControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.productLabelForm.controls[controlName];
    if (!control) {
        return false;
    }
  
    const result: boolean =
        control.hasError(validationType) &&
        (control.dirty || control.touched);
    return result;
    }

  initForm() {
    const formBody = {
      name: [this.label ? this.label.name : null, Validators.compose([Validators.required])],
      code: [this.label ? this.label.code : null],
      unique_id: [this.label ? this.label.uniqueid : null],
      type: [this.label ? this.label.type : null, Validators.compose([Validators.required])],
      producer: [this.label ? this.label.producer.id : null, Validators.compose([Validators.required])],
      produced: [this.label ? this.label.produced : false],
      ingredients: [this.label ? this.label.ingredients : []],
      description: [this.label ? this.label.description : []]
    }
    this.productLabelForm = this.formBuilder.group(formBody);
    if(this.label) {
      console.log(this.label);
      if(this.label.producer) {
        this.checkDefaultPartyExistence(this.label.producer.id)
      }
      if(this.label.produced == 0) {
        this.produced = false
      } else {
        this.produced = true;
      }
      if(this.label.type) {
        this.getResourceType(this.label.type)
      } else {
        this.resourceType = null;
      }
      this.selectIngredient(this.label.ingredients);
    } else {
      this.productLabelForm.reset();
      this.productLabelForm.get('produced').setValue(0)
    }
  }

  public toggleProduced() {
    console.log(this.produced);
    return;
    // this.produced = !this.produced;
  }

  public addPartyModal(): void {
    this.addEditPartyModal.showModal();
    this.selectedParty = null;
  }

  public editParty(party: PartyModel): void {
    this.addEditPartyModal.showModal();
    this.selectedParty = party;
  }

  public addParty(ev) {
    this.parties.unshift(ev);
    this.productLabelForm.controls['producer'].setValue(ev.id);
    this.addEditPartyModal.hideModal();
  }

  public addedOrUpdatedParty(ev) {
    const index = this.parties.findIndex(party => party.id === ev.id);
    if (index !== -1) {
        this.parties[index] = ev;
        this.productLabelForm.get('producer').setValue(ev.id);
        this.addEditPartyModal.hideModal();
    }
  }

  public checkDefaultPartyExistence(producer) {
    
    const INpartyFromPresence = this.parties.some(p => p.id == producer)
    
    if(!INpartyFromPresence) {
      this.subscription.add(this.partyService.getParty(producer).subscribe(data => {
        this.parties.unshift(data.model);
        this.defaultParty = data.model;
        this.productLabelForm.get('producer').setValue(data.model.id);
      }))
    }
  
  }

  public getParties() {
    const url = this.partyService.getUrl('');
    this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
    this.subscription.add(this.partyService.getParties({ limit: this.limit, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
      console.log(data);
      this.parties = data.body.results;
      if(data.body.next) {
        this.getMoreParties = true;
      }
      this.partyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.partyToken) this.getMore = true;
    }))
  }

  filterParties(value) {
    console.log(value);
    this.subscription.add(this.partyService.getParties({search: value}).subscribe(data => {
      console.log(data);
      this.partyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.parties = data.body.results;
      this.parties.unshift(this.defaultParty);
      if(data.body.next) {
        this.getMoreParties = true;
      }
      if(this.partyToken) this.getMoreParties = true
    }))
  }

  public onDeleteParty(party: PartyModel): void {
    this.partyService.deleteParty(party.id).pipe(take(1)).subscribe(
      (): void => {
          this.getParties();
          // this.globalRegistryService.reloadParties();
          this.toastr.success(this.translate.instant('people.party.partyDeletedSuccessfully'));
      }, (): void => {
          this.toastr.success(this.translate.instant('people.party.errorDeletingParty'));
      }
    );
  }

  public getResourceType(type) {
    if(!this.globalRegistry.systemData.resourceTypes.length) {
      return
    }
    console.log(this.globalRegistry.systemData.resourceTypes)
      const resourceType = this.globalRegistry.systemData.resourceTypes.map(obj => this.findObjectById(obj, type)).find(Boolean);
      console.log(resourceType);
      this.resourceTypeId = resourceType.id;
      this.resourceType = resourceType;
      if(resourceType.form_type) {
        this.getFormType(resourceType.form_type);
      } else {
        this.entityInfo = null;
        this.resourceType = null;
      }
  }

  public getFormType(formTypeId) {
    this.typesService.getFormTypeById(formTypeId).subscribe(data => {
      this.entityInfo = data.body.results;
      console.log(this.entityInfo);
    })
  }

  findObjectById(obj, desiredId) {
    if (obj.id === desiredId) {
      return obj;
    }
  
    if (obj.children && obj.children.length > 0) {
      for (const child of obj.children) {
        const foundObject = this.findObjectById(child, desiredId);
        if (foundObject) {
          return foundObject;
        }
      }
    }
  
    return null;
  }

  public selectResourceType(resource?: ResourceTypeModel, parent?:  ResourceTypeModel): void {
    this.selectedResource = resource;
    console.log(this.selectedResource)
    this.parentResource = parent ? parent : null;
    // this.globalRegistry.reloadResourceTypes();
    this.addResourceTypeModal.showModal();
  }

  public onResourceTypeAction(resourceType: ResourceTypeModel): void {
    this.productLabelForm.controls['type'].setValue(resourceType.id);
    this.globalRegistry.reloadResourceTypes();
    this.addResourceTypeModal.hideModal();
    this.rsp.close();
    this.getResourceType(resourceType.id);
  }

  public resourceTypeUpdate(resourceType: ResourceTypeModel): void {
    console.log(resourceType);
    this.productLabelForm.controls['type'].setValue(resourceType.id);
    const index = this.globalRegistry.systemData.resourceTypes.findIndex(resource => resource.id === resourceType.id);
    if (index !== -1) {
      this.globalRegistry.systemData.resourceTypes[index] = resourceType;
    }
    this.addResourceTypeModal.hideModal();
    this.rsp.close();
    this.getResourceType(resourceType.id);
  }

  public refreshIngredientList(ids) {
    console.log(ids);
  }

  public selectIngredient(value) {
    console.log(value);
    const localIngredients = [];
    this.localIngredientsId = value;
    if(this.globalRegistry.systemData.ingredients && this.globalRegistry.systemData.ingredients.length) {
    this.globalRegistry.systemData.ingredients.forEach(ingredient => {
      this.localIngredientsId.forEach(ingredientId => {
        if(ingredient.id == ingredientId) {
          localIngredients.push(ingredient);
        }
      });
    });
  }
    this.localIngredients = localIngredients;
  }

  getTranslations() {
    this.subscription.add(
        this.typesService.getTranslations().subscribe((data) => {
            this.globalRegistry.systemData.translations = data.model;
            return;
        })
    );
}

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  updateProducer(val) {
    this.productLabelForm.get('producer').setValue(val);
  }

  scrollParties() {
    const url = this.partyService.getUrl('');
    console.log(this.partyToken);
    console.log('parties scrolled');
    if(this.getMoreParties) {
    this.partyToken &&
    this.subscription.add(this.partyService.getParties(this.partyToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.partyToken.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search);
        console.log(data);
        if(this.parties) {
          this.parties = [...this.parties, ...data.body.results];
        } else {
          this.parties = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreParties = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.partyToken.offset != params['offset']) {
          this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  public getIngredients() {
    this.globalRegistry.reloadIngredients();
    this.globalRegistry.reloadIngredientsTypes();
    console.log(this.globalRegistry.systemData.ingredients);
  }

  public getIngredient(list:string) {
    this.stockService.getIngredient(list).subscribe(data => {
      console.log(data);
      this.localIngredients = data.body.results;
    })
  }

  public updateIngredient(ingredient: IngredientModel): void {
    console.log(ingredient);
    console.log(this.localIngredients);
    const ingredientIndex = this.localIngredients.findIndex(ing => ing.id === ingredient.id);
    if(ingredientIndex !== -1) {
      this.localIngredients[ingredientIndex] = ingredient;
    }
    this.globalRegistry.reloadIngredients();
    this.updateCropTypeTable();
    this.addEditIngredientModal.hideModal();
  }

  public createdIngredient(ingredient: IngredientModel): void {
    this.globalRegistry.systemData.ingredients.unshift(ingredient);
    // this.globalRegistry.reloadIngredients();
    this.localIngredientsId = this.localIngredientsId || [];
    this.localIngredients.unshift(ingredient);
    this.localIngredientsId.unshift(ingredient.id);
    console.log(ingredient);
    this.updateCropTypeTable();
    this.addEditIngredientModal.hideModal();
  }

  public addIngredient(ingredient: IngredientModel): void {
    this.globalRegistry.systemData.ingredients.unshift(ingredient);
    // this.globalRegistry.reloadIngredients();
    this.localIngredientsId = this.localIngredientsId || [];
    this.localIngredients.unshift(ingredient);
    this.localIngredientsId.unshift(ingredient.id);
    this.label ? this.label.ingredients = this.localIngredientsId : ''
    console.log(ingredient);
    console.log(this.localIngredientsId);
    this.updateCropTypeTable();
    this.addEditIngredientModal.hideModal();
  }

  public deleteLocalIngredient(ingredientId: number): void {
    this.localIngredients = this.localIngredients.filter(ingredient => {
      return ingredient.id !== ingredientId
  });
  const ids = [];
  if(this.localIngredients.length == 0) {
    return
  }
  this.localIngredients.forEach(element => {
    ids.push(element.id);
  });
  console.log(ids);
  console.log(this.localIngredients);
  if(ids.length > 0) {
    this.selectIngredient(ids);
  }
  }

  private updateCropTypeTable(): void {
    this.globalRegistry.reloadCropTypes();
}

  public editIngredient(ingredient) {
    console.log(ingredient);
    this.selectedIngredient = ingredient
    this.addEditIngredientModal.showModal();
  }

  public deleteIngredient(ingredientId) {
    this.stockService.deleteIngredient(ingredientId).pipe(take(1)).subscribe((): void => {
        this.globalRegistry.systemData.ingredients = this.globalRegistry.systemData.ingredients.filter((ingredient: IngredientModel): boolean => ingredient.id !== ingredientId);
        this.toastr.success(this.translate.instant('stock.ingredients.successDeletedMessage'));
    });
  }

  showIngredientPopup() {
    this.selectedIngredient = null;
    this.addEditIngredientModal.showModal();
  }

  openIngredientSelect() {
    this.ingredientSelect.open();
  }

  public submitLabel() {

    // return
    if(this.productLabelForm.value.produced == null) {
      this.productLabelForm.value.produced = 0;
    }
    if(this.productLabelForm.value.ingredients == null) {
      this.productLabelForm.value.ingredients = [];
    }
    console.log(this.productLabelForm.value);
    console.log(this.label);
  if(this.label) {
    this.stockService.updateProductLabel(this.label.id, this.productLabelForm.value).subscribe(data => {
      console.log(data);
      if(data) {
        this.updatedLabel.emit(data.body.results);
        this.toastr.success(this.translate.instant('product.productUpdatedSuccessfully'))
        this.productLabelForm.clearValidators();
      }
        },
          error => {
            this.toastr.error(error.error.results.error);
            console.log(this.label);
            console.log(this.productLabelForm.value);
            return;
          }
          )
  } else {
    this.stockService.addProductLabel(this.productLabelForm.value).subscribe(data => {
      console.log(data);
      this.addedLabel.emit(data.body.results);
      this.toastr.success(this.translate.instant('product.productCreatedSuccessfully'));
      this.productLabelForm.reset();
      this.productLabelForm.clearValidators();
      this.label = null;
      this.localIngredients = [];
      this.descriptions = [];
      this.labelId = -1;
      this.initForm();
    }, error => {
      this.toastr.error(error.error.results.error)
    })
  }

  }

}
