<form [formGroup]="productLabelForm">
    <div class="row">
        <div class="col-6">
            <div class="input-group w-100">
                <mat-form-field>
                    <mat-label>{{ 'product.name' | translate }}</mat-label>
                    <input matInput required placeholder="Name" formControlName="name">
                </mat-form-field>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group w-100">
                <mat-form-field>
                    <mat-label>{{ 'product.code' | translate }}</mat-label>
                    <input matInput placeholder="Code" formControlName="code">
                </mat-form-field>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group w-100">
                <mat-form-field>
                    <mat-label>{{ 'product.uniqueid' | translate }}</mat-label>
                    <input matInput placeholder="Unique ID" formControlName="unique_id">
                </mat-form-field>
            </div>
        </div>
        <div class="col-6" style="margin-bottom: -1rem;">
        <fap-add-edit-delete-select 
            [inputName]="'product.producer' | translate"
            style="max-width: 100%; padding: 0;"
            class="nhalf_width col-4"
            [formControl]="productLabelForm.controls['producer']"
            name="producer"
            [inputIsRequired]="true"
            [apiSearchEnabled]="true"
            [inputNameFieldName]="'name'"
            [inputNameTFieldName]="'nameT'"
            [inputValueFieldName]="'id'"
            [inputSelectValues]="parties"
            (click)="getParties()"
            [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
            (outputOnDelete)="onDeleteParty($event)"
            (outputOnCreate)="selectedParty=null; addEditPartyModal.showModal()"
            (outputOnEdit)="selectedParty=$event; addEditPartyModal.showModal()"
            (outputOnSearch)="filterParties($event)"
            (outputOnScroll)="scrollParties()"
            (selectionChange)="updateProducer($event)"
        >
        </fap-add-edit-delete-select>
        </div>
        <div class="col-6">
            <div class="input-group w-100">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'stock.type' | translate }}</mat-label>
                    <mat-select #rsp class="select" required formControlName="type" (selectionChange)="getResourceType($event.value)">
                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput #resourceFilter placeholder="Search">
                            </mat-form-field>
                            <em class="fa fa-plus center f-20 ml-2" (click)="selectResourceType()"></em>
                        </div>
                        <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.resourceTypes | filter : resourceFilter.value">
                            <span class="mat-option-text">
                                <div class="type-name">
                                    <span *ngIf="parent?.name_t">
                                        <span *ngIf="getTranslation(parent?.name_t) === '' || getTranslation(parent?.name_t) == null || getTranslation(parent?.name_t) == undefined ">{{parent?.name}}</span>
                                        <span *ngIf="getTranslation(parent?.name_t) !== ''">{{getTranslation(parent?.name_t)}}</span>
                                    </span>
                                    <span *ngIf="!parent?.name_t">{{parent.name}}</span>
                                </div>
                                <div class="actions d-flex text-center">
                                    <i class="fa fa-edit align-self-center f-20"  (click)="selectResourceType(parent);$event.stopPropagation()"></i>
                                    <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteResourceType(parent?.id) ;$event.stopPropagation()"></i>
                                </div>
                            </span>
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                <div class="type-name">
                                    <span *ngIf="child?.name_t">
                                        <span *ngIf="getTranslation(child?.name_t) === '' || getTranslation(child?.name_t) == null || getTranslation(child?.name_t) == undefined ">{{child?.name}}</span>
                                        <span *ngIf="getTranslation(child?.name_t) !== ''">{{getTranslation(child?.name_t)}}</span>
                                    </span>
                                    <span *ngIf="!child?.name_t">{{child.name}}</span>
                                </div>
                                <div class="actions d-flex text-center">
                                    <i class="fa fa-edit align-self-center f-20"  (click)="selectResourceType(child, parent);$event.stopPropagation()"></i>
                                    <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteResourceType(child?.id) ;$event.stopPropagation()"></i>
                                </div>
                            </mat-option>
                        </mat-optgroup>
                    </div>
                    </mat-select>
                    <mat-error *ngIf="ifControlHasError('type','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group w-100">
                <div class="checkds"  [ngClass]="from ? 'from' : ''">
                    <input type="checkbox" [(ngModel)]="produced" [ngModelOptions]="{standalone: true}" name="produced" (change)="toggleProduced()" />
                    <span class="checkbx"></span>
                    <label for="produced">{{ 'product.myProduct' | translate }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="label_descriptions w-100 mb-4">
        <fap-info-sections [descriptions]="descriptions" [isEditable]="true"></fap-info-sections>
    </div>
    <div class="form-group w-100" *ngIf="localIngredients">
        <div class="input-group w-100 posabs">
            <mat-form-field (click)="getIngredients()">
              <mat-label>{{ 'product.ingredients' | translate }}</mat-label>
              <mat-select #ingredientSelect formControlName="ingredients" [(ngModel)]="localIngredientsId" multiple (selectionChange)="selectIngredient($event.value)">
                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                  <mat-form-field appearance="fill" class="w-100">
                    <input matInput #ingFilter placeholder="Search">
                  </mat-form-field>
                  <div class="input-group-append pl-1" (click)="showIngredientPopup()">
                    <span class="input-group-text"><fap-round-button></fap-round-button></span>
                  </div>
                </div>
                <div class="scroll_wrap">
                  <mat-option style="height: 0!important;"></mat-option>
                  <mat-option *ngFor="let ingredient of globalRegistry?.systemData?.ingredients | filter : ingFilter.value" [value]="ingredient.id">
                    <div class="opflex">
                      <ng-container *ngFor="let type of globalRegistry?.systemData?.ingredientTypes">
                        <span class="name" *ngIf="type?.id === ingredient?.type">{{ingredient?.sub_type}} ({{type?.nameT ? getTranslation(type?.nameT) : type?.name}})</span>
                      </ng-container>
                      <div class="actions">
                        <i class="fa fa-edit align-self-center f-20" (click)="editIngredient(ingredient);$event.stopPropagation()"></i>
                        <i class="fa fa-trash align-self-center ml-2 f-20" (click)="deleteIngredient(ingredient?.id);$event.stopPropagation()"></i>
                      </div>
                    </div>
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div> 
          <fap-info-sections [descriptions]="descriptions"></fap-info-sections>                 
        <fap-ingredient-table
            [ingredients]="localIngredients"
            [ingredientTypes]="globalRegistry.systemData.ingredientTypes"
            (clicked)="openIngredientSelect()"
            (createdIngredient)="createdIngredient($event); addEditIngredientModal.hideModal()"
            (updatedIngredient)="updatedIngredient($event); addEditIngredientModal.hideModal()"
            (deleteLocalIngredient)="deleteLocalIngredient($event)"
            (deleteLocalIngredient)="deleteLocalIngredient($event)"
            (deleteIngredient)="deleteIngredient($event)">
        </fap-ingredient-table>
    </div>
    <div class=" mt-3 d-flex justify-content-end">
        <button class="btn mr-2" *ngIf="!label?.locked" type="submit" (click)="submitLabel()">{{ 'translations.save' | translate }}</button>
        <button class="btn" type="button" (click)="closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
    </div>
</form>

<fap-modal #addEditPartyModal [showFooter]="false"
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
    (addedParty)="addParty($event); addEditPartyModal.hideModal();"
    (updatedParty)="addedOrUpdatedParty($event); addEditPartyModal.hideModal();"
    (cancel)="addEditPartyModal.hideModal()"
    >
    </fap-add-edit-party>
</fap-modal>

<fap-modal #addResourceTypeModal [inputTitle]="'product.addEditResourceType' | translate" [showFooter]="false">
    <fap-add-edit-resource-type
        [resourceTypes]="globalRegistry?.systemData?.resourceTypes"
        [selectedResource]="selectedResource"
        [parentResource]="parentResource"
        (resourceTypeActionCompleted)="onResourceTypeAction($event)"
        (resourceTypeUpdate)="resourceTypeUpdate($event)"
        (cancel)="addResourceTypeModal.hideModal()"
        >
    </fap-add-edit-resource-type>
</fap-modal>

<fap-modal
    #addEditIngredientModal
    [inputTitle]="'product.addEditIngredients' | translate">
    <fap-add-edit-ingredient
        #addEditIngredient
        [ingredient]="selectedIngredient"
        [context]="'cropType'"
        (cancel)="addEditIngredientModal.hideModal()"
        (createdIngredient)="addIngredient($event); addEditIngredientModal.hideModal()"
        (updatedIngredient)="updateIngredient($event); addEditIngredientModal.hideModal()"
        (deleteLocalIngredient)="deleteLocalIngredient($event)"
        >
    </fap-add-edit-ingredient>
</fap-modal>