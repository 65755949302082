import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UserService } from '../../../core/services/api/user/user.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'fap-water-content-widget',
    templateUrl: './fap-water-content-widget.component.html',
    styleUrls: ['./fap-water-content-widget.component.scss'],
})
export class FapWaterContentWidgetComponent implements OnInit, OnDestroy {
    public selectedWidget;
    @Input()
    public widget: WidgetModel;
    @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
    public toggleMain = false;
    public toggleSub = false;
    public coord = [];
    public size = [];
    public imageUrl = null;
    public infoText = null;
    public status: number = null;
    public showMenu = true;
    public dates = [];
    public selectedDate = null;
    public selectedRecord = null;
    public logic = true;
    public isLoading = false;
    public imageSize = null;
    public apiUrl = environment.baseBackendUrl;
    public waterId: number;
    public fId: number;
    public waterObject;
    public sensorId: number;
    public subscriptions: Array<Subscription> = [];
    public langString: string;

    constructor(
        private globalRegistry: GlobalRegistryService,
        private sensorService: SensorService,
        public toastr: ToastrService,
        public userService: UserService,
        public navService: NavService
    ) {}

    public toggleImageMenu() {
        this.showMenu = !this.showMenu;
    }

    ngOnInit(): void {
        this.getInfo();
        this.subscriptions.push(this.userService.getTimezone.subscribe(data => {
            if(data) {
                console.log(data);
            }
        }));
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    }

    getTranslation(translation) {
        const t =this.globalRegistry.systemData.translations.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
          }
      }

    ngOnDestroy(): void {
        this.subscriptions.forEach(e => {
            e.unsubscribe();
        })
    }

    public getInfo() {
        console.log(this.widget);
        this.sensorId = this.widget.config['settings']['options'].sensor.id;
        function toDateTime(secs) {
            const t = new Date(1970, 0, 1); // Epoch
            t.setSeconds(secs).toLocaleString('en-US');
            return t;
        }
        this.subscriptions.push(this.sensorService
            .getAcquisition({
                cmd: 200,
                lot: this.widget.objectId,
                params: {},
            })
            .subscribe((data) => {
                this.waterId = data.id;
                    this.fId = data.fid;
                    data.forEach((element) => {
                        this.dates.push({
                            id: element.id,
                            fid: element.fid,
                            time: element.time,
                            date: toDateTime(element.time),
                        });
                    });
                // console.log(data);
                const newData = data.sort(function(a, b) {
                    const keyA = new Date(a.id),
                      keyB = new Date(b.id);
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                  });
                  console.log(newData);
                  
                this.waterObject = data;
                this.selectedDate = this.dates[0].time;
                this.fId = this.dates[0].fid;
                this.waterId = this.dates[0].id;
                this.getRecord(this.fId, this.waterId, this.selectedDate);
            }));
    }

    public getRecord(fid, id, time) {
        this.isLoading = true;
        console.log('fid: ' + fid);
        console.log('id: ' + id);
        console.log('time: ' + time);
        this.subscriptions.push(this.sensorService
            .getAcquisition({
                cmd: 201,
                lot: this.widget.objectId,
                params: {
                    time: time,
                    id: id,
                    fid: fid,
                },
            })
            .subscribe((data) => {
                console.log(data);
                this.selectedRecord = data.id;
                this.imageUrl = data.image;
                this.infoText = data.info;
                this.status = data.status;
                if (
                    data.info != null &&
                    data.status != null &&
                    data.id != null &&
                    data.image != null
                ) {
                    this.logic = false;
                }
                this.isLoading = false;
            }));
    }

    public changeDropdown(value) {
        this.dates.forEach((element) => {
            if (element.id == value) {
                console.log(element);
                this.logic = true;
                const snowball = document.getElementById('sball');
                snowball.style.display = 'none';
                this.getRecord(element.fid, element.id, element.time);
            }
        });
    }

    public getLotName(lotId: number = this.widget.objectId): string {
        const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
        return lot ? lot.name : '';
      }

    hideMain() {
        this.toggleMain = false;
    }

    toggleMenu() {
        this.toggleMain = !this.toggleMain;
    }

    getCoords(e) {
        const target = e.target;
        const rect = target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const snowball = document.getElementById('sball');
        snowball.style.display = '';
        snowball.style.position = 'absolute';
        snowball.style.left = (x-75)  + 'px';
        snowball.style.top = (y+21) + 'px';
        console.log(x, y);

        const myImg1 = document.querySelector('#myImgId') as HTMLImageElement;
        const realWidth = myImg1.offsetWidth;
        const imageWidth = myImg1.parentElement.clientWidth;
        const realHeight = myImg1.offsetHeight;
        const imageHeight = myImg1.parentElement.clientHeight;
        this.coord = [x, y];
        this.imageSize = [realWidth, realHeight];
        this.size = [imageWidth, imageHeight];
        console.log(this.size);
        console.log(this.imageSize);
        this.showModal(this.widget);
    }

    showModal(widget): void {
        this.selectedWidget = widget;
        this.confirmPopup.showModal();
    }

    closeModal(widget): void {
        this.selectedWidget = widget;
        this.confirmPopup.hideModal();
    }

    public getAcquisition() {
        this.isLoading = true;
        this.subscriptions.push(this.sensorService
            .getAcquisition({
                cmd: 202,
                lot: this.widget.objectId,
                params: { time: this.selectedDate },
            })
            .subscribe((data) => {
                console.log(data);
                this.selectedRecord = data.id;
                this.imageUrl = data.image;
                this.infoText = data.info;
                this.status = data.status;
                if (data.id != null && data.image != null) {
                    this.logic = false;
                }
                this.isLoading = false;
            }));
    }

    createRequest() {
        this.isLoading = true;
        this.subscriptions.push(this.sensorService
            .getAcquisition({
                cmd: 203,
                lot: this.widget.objectId,
                sensor: this.sensorId,
                params: {
                    time: this.selectedDate,
                    coord: this.coord,
                    size: this.size,
                    imageSize: this.imageSize,
                },
            })
            .subscribe((data) => {
                console.log(data);
                this.toastr.success("Data acquisition is over "+data.network, 'Connectivity', {
                    timeOut: 6000,
                  });
                this.isLoading = false;
            }));
        this.closeModal(this.selectedWidget);
    }
}
