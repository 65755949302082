import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { FapModalComponent } from '../../fap-modal/fap-modal.component';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../services/nav.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fap-add-edit-info-section',
  templateUrl: './fap-add-edit-info-section.component.html',
  styleUrls: ['./fap-add-edit-info-section.component.scss']
})
export class FapAddEditInfoSectionComponent implements OnInit, OnDestroy, OnChanges{

  @Input() description = null;
  @Input() descriptionId = -1;
  @Input() public langString;

  @Output()
  public closeModal: EventEmitter<any> = new EventEmitter();
  @Output()
  public addedDescription: EventEmitter<any> = new EventEmitter();
  @Output()
  public updatedDescription: EventEmitter<any> = new EventEmitter();

  public infosectionForm: FormGroup;
  public editor: Editor;
  public html = '';
  public translation;
  public bodyTranslation;
  public label = '';
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  public descriptionImages = [];
  public subscriptions: Array<Subscription> = [];

  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('addEditBodyTranslationModal')
  public addEditBodyTranslationModal: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;

  constructor(private formBuilder: FormBuilder, public globalRegistry: GlobalRegistryService, private typeService: TypesService, private navService: NavService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.initForm();
    this.editor = new Editor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'descriptionId') && this.descriptionId) {
      console.log(changes);
      console.log(this.descriptionId);
      this.getInfoSection(this.descriptionId);
      this.initForm();
    }
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  getInfoSection(infoSectionId) {
    console.log(infoSectionId)
  }

  initForm() {
    const formBody = {
      name: [this.description ? this.description.name_t ? this.getTranslation(this.description.name_t) : null : null, Validators.compose([Validators.required])],
      name_t: [this.description ? this.description.name_t : null, Validators.compose([Validators.required])],
      // body: [this.description ? this.description.body_t ? this.getTranslation(this.description.body_t) : null : null, Validators.compose([Validators.required])],
      body_t: [this.description ? this.description.body_t : null, Validators.compose([Validators.required])],
      images: [this.description ? this.description.images : []]
    }
    this.infosectionForm = this.formBuilder.group(formBody);
    if(this.description) {
      console.log(this.description);
      if(this.description.name_t) {
        console.log(this.getTranslation(this.description.name_t));
      }
      this.html = this.getTranslation(this.description.body_t);
    }
  }

  getTranslation(translation): string {
    if(!translation) {
      return
    }
    
      const t = this.globalRegistry.systemData.translations.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
          if(t[0][this.langString] === null || t[0][this.langString] === '') {
            return translation
          } else {
            return t[0][this.langString];
          }
        } else {
          console.log('translation not found')
          let newTranslation = this.getTranslationByKey(translation);
          return newTranslation[this.langString] ? newTranslation[this.langString] : '';
      }
    
  }

  getTranslationByKey(key) {
    this.subscriptions.push(
      this.typeService.getTranslationByKey(key).subscribe(data => {
        if(data) {
          this.translation = data.body.results;
          const index = this.globalRegistry.systemData.translations.findIndex(item => item.id === data.body.results.id);
          if (index !== -1) {
            this.globalRegistry.systemData.translations[index] = data.body.results;
            this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
            this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
          } else {
            this.globalRegistry.systemData.translations.push(data.body.results);
            this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
            this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
          }
          return data.body.results;
        }
      })
    )
  }

  public createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
    arrayOfObjects.forEach(obj => {
        objectMap.set(obj.id, obj);
        if(obj.children && obj.children.length) {
           this.createObjectMap(obj.children, objectMap);
        }
    });
    return objectMap;
  }

  addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
    this.label = label;
    const name = this[inputElement].nativeElement.value;
    const type: any = this.convertKeysToCamelCase(translationTarget);
    console.log(name);
    console.log(type);
    if (name === null || name === '' || type.nameT === null) {
        this.translation = null;
        popup.showModal();
    } else {
        for (const translation of this.globalRegistry.systemData.translations) {
            if (type && translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                popup.showModal();
                return;
            }
        }

        if (Object.keys(type).length === 0 && name) {
            const newTranslation = this[form].controls['name_t'].value;
            for (const translation of this.globalRegistry.systemData.translations) {
                if (translation.id === newTranslation) {
                    this.translation = translation;
                    console.log(this.translation);
                    popup.showModal();
                    return;
                }
            }
        }
    }
  }

  addEditBodyTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
    this.label = label;
    const name = this[form].value.body_t;
    const type: any = this.convertKeysToCamelCase(translationTarget);
    console.log(name);
    console.log(type);
    if (name === null || name === '' || type.bodyT === null) {
        this.translation = null;
        popup.showModal();
    } else {
        for (const translation of this.globalRegistry.systemData.translations) {
            if (type && translation.id === type.bodyT) {
                this.translation = translation;
                console.log(this.translation);
                popup.showModal();
                return;
            }
        }

        if (Object.keys(type).length === 0 && name) {
            const newTranslation = this[form].controls['body_t'].value;
            for (const translation of this.globalRegistry.systemData.translations) {
                if (translation.id === newTranslation) {
                    this.translation = translation;
                    console.log(this.translation);
                    popup.showModal();
                    return;
                }
            }
        }
    }
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
            p1.toUpperCase()
        );
        camelCaseObj[camelCaseKey] =
            obj[key] instanceof Object
                ? this.convertKeysToCamelCase(obj[key])
                : obj[key];
    }
    return camelCaseObj;
  }

  onAddUpdateTranslation(translation) {
    this.translation = translation;
    this.infosectionForm.controls['name_t'].setValue(translation.id);
    this.infosectionForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
  }

  onAddUpdateBodyTranslation(translation) {
    this.translation = translation;
    this.infosectionForm.controls['body_t'].setValue(translation.id);
    this.html = translation[this.langString]
    this.addEditBodyTranslationModal.hideModal();
  }

  submitDescription() {
    let imageIds = [];
    this.infosectionForm.value.images.forEach(element => {
      imageIds.push(element.id);
    });
    let body = {
      name_t: this.infosectionForm.value.name_t,
      body_t: this.infosectionForm.value.body_t,
      images: imageIds,
    }
    console.log(body);
    if(this.description.id) {
      this.updatedDescription.emit(body);
      this.infosectionForm.reset();
      this.description = null;
    } else {
      this.addedDescription.emit(body);
      this.infosectionForm.reset();
      this.description = null;
    }
  }

  getHtmlContent(html ) {
    if(!html) {
      return
    }
    let sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(html);
    if(sanitizedHtml) {
      return sanitizedHtml
    } else {
      return ''
    }
  }

  logHtml() {
    console.log(this.html);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

}
