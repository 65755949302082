<div class="entries">
    <div class="sbox">
        <fap-section-container [title]="'productsUsage.productsUsage' | translate">
            <div class="table-responsive">
            <table class="entry_table">
                <thead>
                    <tr>
                        <th>{{ 'productsUsage.product' | translate }}</th>
                        <th>{{ 'productsUsage.quantity' | translate }}</th>
                        <th>{{ 'productsUsage.appliedTo' | translate }}</th> <!--Aplicat la-->
                        <th>{{ 'productsUsage.waterDilution' | translate }}</th> <!--Necesar apă-->
                        <th>{{ 'productsUsage.repauseTime' | translate }}</th> <!--Repetare după-->
                        <th>{{ 'productsUsage.onsetTime' | translate }}</th> <!--Timp așteptare-->
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let treatment of treatments">
                        <td>
                            {{treatment?.label?.name}} 
                            <!-- <b>{{ treatment?.product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b> -->
                             <div><b>{{ treatment?.producer?.name }}</b></div>
                        </td>
                        <td><b>{{treatment?.quantity_min}} to {{treatment?.quantity_max}}</b>
                            {{ treatment?.quantity_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                        </td>
                        <td><b>{{treatment?.apply}}</b>
                            {{ treatment?.apply_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                        </td>
                        <td><b>{{treatment?.dilution}}</b>
                            {{ treatment?.dilution_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                        </td>
                        <td><b>{{treatment?.repause_min}} to {{treatment?.repause_max}}</b>
                            {{ treatment?.repause_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                        </td>
                        <td><b>{{treatment?.reaction_min}} to {{treatment?.reaction_min}}</b>
                            {{ treatment?.reaction_unit | getUnit: globalRegistry?.systemData?.unitsMap }}</td>
                        <td>
                            <action-buttons 
                                [locked]="treatment?.locked" 
                                (editEmit)="editTreatment.emit(treatment)" 
                                (deleteEmit)="deleteTreatment.emit(treatment?.id)"
                            ></action-buttons>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </fap-section-container>
    </div>
</div>