import { Component, EventEmitter, HostBinding, Output } from "@angular/core";

@Component({
  selector: "th[resizable]",
  templateUrl: "./resizable.component.html",
  styleUrls: ["./resizable.component.scss"],
})
export class ResizableComponent {
  @HostBinding("style.width.px")
  width: number | null = null;
  @Output() widthChange = new EventEmitter<number>();

  onResize(width: number) {
    this.width = width;
    this.widthChange.emit(width);
  }
}
