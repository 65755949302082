import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { CropModel } from '../../../core/models/crops/crop.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { CropService } from '../../../core/services/api/crop/crop.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'fap-alert-widget',
    templateUrl: './fap-alert-widget.component.html',
    styleUrls: ['./fap-alert-widget.component.scss'],
})
export class FapAlertWidgetComponent implements OnInit, OnDestroy, OnChanges {
    public selectedWidget;
    @Input()
    public widget: WidgetModel;
    @Input()
    public crops: Array<CropModel> = [];
    @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
    public lotId;
    public disease: any;
    public translatedNames: any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public toggleMain = false;
    public toggleSub = false;
    
    public crop: CropModel;
    public apiUrl = environment.baseBackendUrl;
    public title: string;
    public alertData;
    public messages = [];
    public errorMsg;
    public mediaUrl = environment.mediaUrl;

    constructor(
        public companyService: CompanyService,
        public globalRegistry: GlobalRegistryService,
        public navService: NavService,
        public cropService: CropService,
        public toastyService: ToastrService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if(this.widget.config['disease']) {
        this.subscription.add(
            this.cropService.getDisease(this.widget.config['disease']).subscribe(data => {
                this.disease = data.body.results;
                console.log(this.disease);
            })
        )
        if(this.lotId) {
        this.subscription.add(
            this.cropService
                .getAlertData(this.lotId, this.widget.config['disease'])
                .subscribe((data) => {
                    this.alertData = data.body.results[0];
                    if(this.alertData.messages) {
                      this.messages = Object.values(this.alertData.messages);
                    }
                    console.log(data.body.results[0]);
                },
                (error) => {
                    this.errorMsg = this.getTranslation(error.error.results.error);
                })
        );
            }
        }
        this.langString = localStorage.getItem('language');
        this.subscription.add(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        if(Object.prototype.hasOwnProperty.call(changes, 'crops') && Object.prototype.hasOwnProperty.call(changes, 'widget')) {
            console.log(this.crops);
            this.lotId = this.widget.objectId;
            const crop = this.crops.filter((c) => c.lot === this.lotId);
            this.crop = crop[0];
            console.log(this.crop);
        }
    }

    getTranslation(translation) {
        const t = this.translatedNames.filter((trans) => {
            return trans.id === translation;
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
        }
    }

    public getLotName(lotId: number = this.widget.objectId): string {
        const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
        return lot ? lot.name : '';
      }

    hideMain() {
        this.toggleMain = false;
    }

    toggleMenu() {
        this.toggleMain = !this.toggleMain;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    showModal(widget): void {
      this.selectedWidget = widget;
      this.confirmPopup.showModal();
  }

  closeModal(widget): void {
      this.selectedWidget = widget;
      this.confirmPopup.hideModal();
  }

  verifyPayment() {
    this.toastyService.warning(
      this.translate.instant("widget.contactSupport")
    )
  }
}
