import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MapService } from '../../../../shared/layout/fap_main-map/service/map-service';
import { CropService } from '../../../../core/services/api/crop/crop.service';
import { WidgetsService } from '../../../../core/services/api/widgets/widgets.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { FapModalComponent } from '../../../../shared/partials';
import { TranslateService } from '@ngx-translate/core';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { ProductUsageTypeModel } from '../../../../core/models/stock/product_usage_type.model';

@Component({
  selector: 'fap-treatments-list',
  templateUrl: './treatments-list.component.html',
  styleUrls: ['./treatments-list.component.scss']
})
export class TreatmentsListComponent implements OnInit, OnDestroy {

  @ViewChild('addEditTreatmentsModal')
  public addEditTreatmentsModal: FapModalComponent;

  public treatments: any[] = [];
  public treatmentId: number = -1;
  public treatment = null;
  public productUsageTypes: Array<ProductUsageTypeModel> = [];
  public formTypes = [];

  constructor(private toastr: ToastrService, private mapService: MapService, private cropService: CropService, private widgetService: WidgetsService, private cacheService: CacheResolverService, private translate: TranslateService, private stockService: StockService, private typeService: TypesService) {}

  ngOnInit(): void {
    this.mapService.hideMap();
    this.widgetService.setSize(12);
    this.getTreatments();
    this.getProductUsageTypes();
    this.getFormTypes();
    this.widgetService.setMapResize(false);
    this.getInfoSections();
  }

  getInfoSections() {
    this.typeService.getInfoSections().subscribe(data => {
      console.log(data)
    })
  }

  getTreatments() {
    let url = this.stockService.getUrl('treatments/')
    this.stockService.getProductUsage().subscribe(data => {
      this.cacheService.delete(url);
      console.log(data);
      this.treatments = data.body.results;
    });
  }

  public getFormTypes() {
    const url = this.typeService.getUrl('form/');
    this.cacheService.delete(url);
    this.typeService.getFormTypes().subscribe((data) => {
        this.formTypes = data.body.results;
        console.log(data.body.results);
    });
  }

  public getProductUsageTypes() {
    let url = this.typeService.getUrl('product_usage/')
    this.typeService.getProductUsageTypes().subscribe(data => {
      this.cacheService.delete(url);
      this.productUsageTypes = data.model;
    })
  }

  newTreatment() {
    this.addEditTreatmentsModal.showModal();
    this.treatment = null;
    this.treatmentId = -1;
  }

  editTreatment(treatment) {
    let treatmentId = treatment.id;
    this.stockService.getProductUsageById(treatmentId).subscribe(data => {
      if(data) {
        this.treatment = data.body.results;
        this.treatmentId = this.treatment.id;
        this.addEditTreatmentsModal.showModal();
      }
    }, (error) => {
      this.toastr.error(error.error.results.error)
    })
  }

  deleteTreatment(treatmentId: number) {
    this.stockService.deleteProductUsage(treatmentId).subscribe(
        () => {
            this.treatments = this.treatments.filter(product => product.id !== treatmentId);
            this.toastr.success(this.translate.instant('productsUsage.productUsageDeletedSuccessfully'));
        },
        error => {
          this.toastr.error(error.error.results.error);
        }
    );
  }

  updateTreatment(updatedProduct: any) {
    const index = this.treatments.findIndex(product => product.id === updatedProduct.id);
    if (index !== -1) {
      this.treatments[index] = updatedProduct;
      this.treatmentId = -1;
      this.addEditTreatmentsModal.hideModal();
      this.toastr.success(this.translate.instant('productsUsage.productUsageUpdatedSuccessfully'));
    }
  }

  addTreatment(product: any) {
    this.treatments.unshift(product);
    this.treatmentId = -1;
    this.addEditTreatmentsModal.hideModal();
    this.toastr.success(this.translate.instant('productsUsage.productUsageCreatedSuccessfully'));
  }

  ngOnDestroy(): void {
    this.mapService.showMap();
    this.widgetService.setSize(6);
    this.widgetService.setMapResize(true);
  }
}
