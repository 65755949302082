import { LotModel } from './../../../../../core/models/lot/lot.model';
import {
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { WidgetInterface } from '../../../../../core/interfaces/widget/widget.interface';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { ToastrService } from 'ngx-toastr';
import { WidgetModel } from '../../../../../core/models/widget/widget.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { TranslateService } from '@ngx-translate/core';
import {
    Router,
    ActivatedRoute,
    Params
} from '@angular/router';
import {
    combineLatest,
    Subscription
} from 'rxjs';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { FieldModel } from '../../../../../core/models/field/field.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { FieldService } from '../../../../../core/services/api/farm/field.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { LotService } from '../../../../../core/services/api/farm/lot.service';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { WidgetsWebsocketService } from '../../../../../core/services/websocket/widgets/widgets-websocket.service';
import { WidgetsDataInterface } from '../../../../../core/services/websocket/widgets/data/widgets-data.interface';
import { WidgetsActions } from '../../../../../core/services/websocket/widgets/data/widgets-actions.enum';
import { WidgetsEnum } from '../../../../../core/enums/widgets.enum';
import { ListWidgetItemInterface } from '../../../../../shared/widgets/data/list-widget-item.interface';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { FapBaseModel } from '../../../../../core/base/fap-base-model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    templateUrl: './home-container.component.html'
})
export class HomeContainerComponent implements OnInit, OnDestroy {

    public widgets: Array<WidgetModel>;
    public sensors: Array<SensorModel>;
    public fields: Array<FieldModel> = [];

    public filteredFarms: Array<FarmModel> = [];
    public filteredLots: Array<LotModel> = [];
    public filteredFields: Array<FieldModel> = [];

    public filteredWidgets: Array<WidgetModel> = [];

    public filterFarmIds: Array<number> = [];
    public filterLotIds: Array<number> = [];

    public subscriptions: Array<Subscription> = [];

    constructor(public globalRegistry: GlobalRegistryService,
                public widgetsService: WidgetsService,
                public toastr: ToastrService,
                public translateService: TranslateService,
                public router: Router,
                public mapService: MapService,
                public farmService: FarmService,
                public lotService: LotService,
                public fieldService: FieldService,
                public sensorService: SensorService,
                public activatedRoute: ActivatedRoute,
                public activityService: ActivityService,
                public widgetsWebsocket: WidgetsWebsocketService,
                public cacheService: CacheResolverService
                ) {
        this.subscriptions.push(combineLatest([
            this.fieldService.getFields(),
            this.widgetsService.getWidgets(),
            activatedRoute.queryParams]).subscribe(([fields, widgets, queryParams]:
                [ ResponseModel<FieldModel[]>, ResponseModel<WidgetModel[]>, Params]): void => {
                this.fields = fields.model;
                this.widgets = widgets.model;
                this.filterFarmIds = [];
                this.filterLotIds = [];
                if (queryParams['farms'] !== undefined) {
                    if (Array.isArray(queryParams['farms'])) {
                        this.filterFarmIds = queryParams['farms'].map((farmId: string): number => Number(farmId));
                    } else {
                        this.filterFarmIds = [Number(queryParams['farms'])];
                    }
                }
                if (queryParams['lots'] !== undefined) {
                    if (Array.isArray(queryParams['lots'])) {
                        this.filterLotIds = queryParams['lots'].map((lotId: string): number => Number(lotId));
                    } else {
                        this.filterLotIds = [Number(queryParams['lots'])];
                    }
                }
                this.filteredFarms = this.getFilteredFarms(this.filterFarmIds, this.filterLotIds);
                this.filteredLots = this.getFilteredLots(this.filterFarmIds, this.filterLotIds);
                this.filteredFields = this.getFilteredFields(this.filterFarmIds, this.filterLotIds);
                this.initMap();
                this.loadWidgets(true);
            }));
    }

    public ngOnInit(): void {
        this.sensorService.getSensors().subscribe((sensors: ResponseModel<SensorModel[]>): void => { this.sensors = sensors.model; });
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    public addWidget(widget: WidgetInterface): void {
        this.widgetsService.addWidget(widget).subscribe((): void => {
            this.toastr.success(this.translateService.instant('widget.widgetAddedSuccessfully'));
            this.loadWidgets();
        }, (): void => {
            this.toastr.error(this.translateService.instant('widget.failedToAddWidget'));
        });
    }

    public deleteWidget(widgetId: number): void {
        this.widgetsService.deleteWidget(widgetId).subscribe((): void => {
            this.toastr.success(this.translateService.instant('widget.widgetDeletedSuccessfully'));
            this.loadWidgets();
        }, (): void => {
            this.toastr.error(this.translateService.instant('widget.failedToDeleteWidget'));
        });
    }

    private initMap(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        this.mapService.resetMap();
        this.mapService.isEditMode = false;
        const farmPolygons: Array<MapPolygonInterface> = this.filteredFarms.map((farm: FarmModel): MapPolygonInterface =>
            ({
                points: MapHelper.convertToAGMPolygon(farm.coords.coordinates[0]),
                strokeColor: '#a31f1f',
                fillColor: '#de3333'
            }));
        const fieldPolygons: Array<MapPolygonInterface> = this.filteredFields.map((field: FieldModel): MapPolygonInterface =>
        ({
            points: MapHelper.convertToAGMPolygon(field.coords.coordinates[0]),
            strokeColor: '#33de55',
            fillColor: '#248a38'
        }));
        const lotPolygons: Array<MapPolygonInterface> = this.filteredLots.map((lot: LotModel): MapPolygonInterface =>
        ({
            points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
            fillColor: '#ffff00',
            strokeColor: '#ffff00',
        }));
        this.mapService.mapPolygons = [...farmPolygons, ...fieldPolygons, ...lotPolygons];
        this.mapService.centerMapOnPolygons();
    }

    private getFilteredFarms(farmIds: number[], lotIds: number[]): FarmModel[] {
        if ((!farmIds || farmIds.length === 0) && (!lotIds || lotIds.length === 0)) {
            return this.globalRegistry.systemData.farms;
        }
        const filteredFarms: FarmModel[] = this.globalRegistry.systemData.farms.filter((farm: FarmModel): boolean => farmIds
            .some((farmId: number): boolean => farmId === farm.id));
        return filteredFarms;
    }

    private getFilteredLots(farmIds: number[], lotIds: number[]): LotModel[] {
        if ((!farmIds || farmIds.length === 0) && (!lotIds || lotIds.length === 0)) {
            return this.globalRegistry.systemData.lots;
        }
        let filteredLots: LotModel[] = [];
        if (lotIds && lotIds.length > 0) {
            filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => lotIds
            .some((lotId: number): boolean => lotId === lot.id));
        } else {
            filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => farmIds.includes(lot.farm));
        }
        return filteredLots;
    }

    private getFilteredFields(farmIds: number[], lotIds: number[]): FieldModel[] {
        if ((!farmIds || farmIds.length === 0) && (!lotIds || lotIds.length === 0)) {
            return this.fields;
        }
        let filteredLots: Array<LotModel> = [];
        if(!lotIds || lotIds.length === 0) {
            filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => farmIds.includes(lot.farm));
        } else {
            filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => lotIds.includes(lot.id));
        }
        const filteredFields: FieldModel[] = this.fields.filter((field: FieldModel): boolean => filteredLots
            .some((lot: LotModel): boolean => lot.id === field.lot));
        return filteredFields;
    }

    private updateWidgetData(widgetData: {}): void {
        const foundWidget: WidgetModel = this.widgets.find((widget: WidgetModel): boolean => widget.id === widgetData['widget_id']);
        if (!foundWidget) {
            return;
        }
        if (foundWidget.type === 'weather') {
            foundWidget.config = FapBaseModel.parseJson(WeatherModel, widgetData['data']);
        }
        if (foundWidget.type === 'farms') {
            const farmList: Array<ListWidgetItemInterface> = widgetData['data'].map((farm: FarmModel): {} => ({ id: farm.id, name: farm.name}));
            foundWidget.config['items'] = farmList;
            foundWidget.config['clickFunction'] = (farmId: number): void => {
                this.router.navigate(['/pages/farm/edit/' + farmId]);
            };
        }
        if (foundWidget.type === 'lots') {
            const lotList: Array<ListWidgetItemInterface> = widgetData['data'].map((lot: LotModel): {} => ({ id: lot.id, name: lot.name}));
            foundWidget.config['items'] = lotList;
            foundWidget.config['clickFunction'] = (lotId: number): void => {
                this.router.navigate(['/pages/lot/edit/' + lotId]);
            };
        }
        if (foundWidget.type === 'fields') {
            const fieldList: Array<ListWidgetItemInterface> = widgetData['data'].map((field: FieldModel): {} => ({ id: field.id, name: field.name}));
            foundWidget.config['items'] = fieldList;
            foundWidget.config['clickFunction'] = (fieldId: number): void => {
                this.router.navigate(['/pages/fields/edit/' + fieldId]);
            };
        }
        if (foundWidget.type === 'activities') {
            foundWidget.config['activities'] = widgetData['data'].map((activity: {}): ActivityModel => {
                const activityModel: ActivityModel = new ActivityModel();
                activityModel.type = activity['activity_type'];
                activityModel.ended = activity['ended'];
                // activityModel.group = activity['group'];
                activityModel.id = activity['id'];
                activityModel.info = activity['info'];
                activityModel.started = activity['started'];
                activityModel.name = activity['name'];
                return activityModel;
            });
            foundWidget.config['activityGroups'] = [];
            foundWidget.config['deleteActivityFunction'] = (activityId: number): void => {
                this.subscriptions.push(this.activityService.deleteActivity(activityId).subscribe((): void => {
                    this.toastr.success(this.translateService.instant('activity.activityDeletedSuccessfully'));
                    this.loadWidgets();
                }));
            };
            foundWidget.config['editActivityFunction'] = (activity: ActivityModel): void => {
                this.subscriptions.push(this.activityService.updateActivity(activity.id, activity).subscribe((): void => {
                    this.toastr.success(this.translateService.instant('activity.activityUpdatedSuccessfully'));
                    this.loadWidgets();
                }));
            };
            foundWidget.config['addActivityFunction'] = (activity: ActivityModel): void => {
                this.subscriptions.push(this.activityService.createActivity(activity).subscribe((): void => {
                    this.toastr.success(this.translateService.instant('activity.activityCreatedSuccessfully'));
                    this.loadWidgets();
                }));
            };
        }
        if (foundWidget.contentType === 'farm') {
            foundWidget.config['objectName'] = this.globalRegistry.systemData.farms
                .find((farm: FarmModel): boolean => farm.id === foundWidget.objectId)?.name;
        } else if (foundWidget.contentType === 'lot'){
            foundWidget.config['objectName'] = this.globalRegistry.systemData.lots
                .find((lot: LotModel): boolean => lot.id === foundWidget.objectId)?.name;
        }
        if (foundWidget.contentType !== 'user') {
            foundWidget.config['contentType'] = foundWidget.contentType === 'farm' ? 'farm.farm' : 'farm.lot.lot'; // we need it in the config
        }
    }

    private loadWidgets(onlyFilter = false): void {
        const url = this.widgetsService.getUrl('widgets/');
        this.cacheService.delete(url);
        if (onlyFilter) {
            this.addWidgetMockData();
            this.initWidgetsWebsocket();
            this.filterWidgets();
        } else {
            this.widgetsService.getWidgets().subscribe((widgets: ResponseModel<WidgetModel[]>): void => {
                this.widgets = widgets.model;
                this.filteredWidgets = widgets.model;
                this.addWidgetMockData();
                this.initWidgetsWebsocket();
                this.filterWidgets();
            });
        }
    }

    private filterWidgets(): void {
        this.filteredWidgets = this.widgets.filter((widget: WidgetModel): boolean => {
            if (this.filterLotIds.length > 0) {
                return widget.contentType === 'lot' && this.filterLotIds.includes(widget.objectId);
            }
            if (this.filterFarmIds.length > 0) {
                if (widget.contentType === 'lot') {
                    const matchingLot: LotModel = this.globalRegistry.systemData.lots.find((lot: LotModel): boolean => lot.id === widget.objectId);
                    return this.filterFarmIds.includes(matchingLot.farm);
                }
                if (widget.contentType === 'farm') {
                    return this.filterFarmIds.includes(widget.objectId);
                }
                return false; // reached this line, filters applied, content type other than lot or farm => filter out
            }
            return true; // no filters to apply, show everything
        });
    }

    // this should be removed when widgets are updated from ws
    private addWidgetMockData(): void {
        this.widgets.forEach((widget: WidgetModel): void => {
            if (['soil', 'temperature', 'humidity', 'moist', 'dielectric', 'rad', 'soilTemperature', 'illuminance', 'pressure']
            .includes(widget.type)) {
                widget.config['chartMultiData'] = [
                    {
                        name: 'Value',
                        series: [
                            {
                                name: '2020-07-07',
                                value: 30
                            },
                            {
                                name: '2020-07-08',
                                value: 13
                            },
                            {
                                name: '2020-07-09',
                                value: 28
                            },
                            {
                                name: '2020-07-10',
                                value: 17
                            },
                            {
                                name: '2020-07-11',
                                value: 17
                            },
                            {
                                name: '2020-07-12',
                                value: 31
                            },
                            {
                                name: '2020-07-13',
                                value: 29
                            }
                        ]
                    }
                ];
            }
            if (['photosynthRadiation', 'rainfall'].includes(widget.type)) {
                widget.config['chartData'] = [
                    {
                        name: '11:00',
                        value: 6
                    },
                    {
                        name: '12:00',
                        value: 3
                    },
                    {
                        name: '13:00',
                        value: 3
                    },
                    {
                        name: '14:00',
                        value: 8
                    },
                    {
                        name: '15:00',
                        value: 9
                    },
                    {
                        name: '16:00',
                        value: 10
                    }
                ];
            }
            if (widget.contentType === 'farm') {
                widget.config['objectName'] = this.globalRegistry.systemData.farms
                    .find((farm: FarmModel): boolean => farm.id === widget.objectId)?.name;
            } else if (widget.contentType === 'lot'){
                widget.config['objectName'] = this.globalRegistry.systemData.lots
                    .find((lot: LotModel): boolean => lot.id === widget.objectId)?.name;
            }
            if (widget.contentType !== 'user') {
                widget.config['contentType'] = widget.contentType === 'farm' ? 'farm.farm' : 'farm.lot.lot'; // we need it in the config
            }
        });
    }

    private initWidgetsWebsocket(): void {
        const widgetsData: WidgetsDataInterface = {
            type: WidgetsActions.REQUEST_DATA,
            data: this.getWidgetsWebsocketConfig()
        };

        this.subscriptions.push(this.widgetsWebsocket.subscribe((response: any): void => {
            this.updateWidgetData(response);
        }));
        setTimeout((): void => {
            this.widgetsWebsocket.sendWidgetConfig(widgetsData);
        }, 1000);
    }

    private getWidgetsWebsocketConfig(): {}[] {
        const widgetWebsocketConfigs: {}[] = [];
        this.widgets.forEach((widget: WidgetModel): void => {
            switch (widget.type) {
                case WidgetsEnum.Humidity:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.Moist:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.Dielectric:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.Rad:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.SoilTemperature:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.PhotosynthRadiation:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Illuminance:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Pressure:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Temperature:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.Soil:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.Wind:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        sensors: [widget.config['sensor']]
                    });
                    break;
                case WidgetsEnum.Weather:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id,
                        config: widget.config
                    });
                    break;
                case WidgetsEnum.Lots:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Farms:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Fields:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Activities:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                case WidgetsEnum.Alerts:
                    widgetWebsocketConfigs.push({
                        widget_id: widget.id
                    });
                    break;
                default:
                //
            }
        });
        return widgetWebsocketConfigs;
    }
}
