import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../services/nav.service';

@Component({
  selector: 'chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {

  @Input() isEditable = false;
  @Input() isDeleteable = false;
  @Input() searchEnabled = false;
  @Input() addEnabled = false;
  @Input() multiString = false;
  @Input() label = '';
  @Input() items: any = [];
  @Input() selectedItems: any[] = [];
  @Input() fieldName = 'name_t';
  @Input() type = 'entity';
  @Input() showFieldUniqueId: boolean = false;

  @Output() public showAddModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public filterItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() public editItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() public customEditEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public emitScroll: EventEmitter<any> = new EventEmitter<any>();
  @Output() public deleteItem: EventEmitter<number | string> = new EventEmitter<number | string>();
  @Output() public updatedItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() public removeItemFromList: EventEmitter<any> = new EventEmitter<any>();


  public langString: string;
  public subscriptions:Array<Subscription> = [];

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService) { }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
      }));
  }

  scrolledDown() {
    console.log('scrolled')
  }

  setItem(event) {
    let newValues = event.value;
    let oldValues = [...this.selectedItems];
    this.selectedItems = [...new Set([...oldValues, ...newValues])];
    this.updatedItems.emit(this.selectedItems);
  }

  getNgStyle() {
    const styles: { [key: string]: any } = {
      'line-height': this.showFieldUniqueId ? '20px' : null,
      'right.px': this.addEnabled ? 65 : 35,
    };

    if (!this.addEnabled) {
      styles['max-width'] = '100%';
      styles['width'] = '100%';
    }

    return styles;
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.emitScroll.emit(true);
      console.log('scrolled')
    }
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  removeItem(id) {
    this.selectedItems = this.selectedItems.filter(item => item !== id);
    this.updatedItems.emit(this.selectedItems);
    this.removeItemFromList.emit(id);
  }

}
