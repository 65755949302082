<ng-container>
<div class="chips_container">
<mat-label class="col-form-label float">{{ label }}</mat-label>
<div class="chips">
    <ng-container *ngIf="selectedItems && selectedItems.length > 0">
        <ng-container *ngFor="let item of items">
            <span *ngFor="let value of selectedItems">
                <ng-container *ngIf="item.id == value">
                    <div class="chip" (click)="customEditEmit.emit(item)">
                        <ng-container *ngIf="item?.name && (!item?.name_t || !item.nameT)">
                            {{ item?.name }}
                        </ng-container>
                        <ng-container *ngIf="(item?.name_t && !item.name) || (!item?.name_t && item.name)">
                            {{ item?.name_t ? getTranslation(item?.name_t) : '' }}
                        </ng-container>
                        <ng-container *ngIf="(item?.nameT && !item.name) || (!item?.nameT && item.name)">
                            {{ item?.nameT ? getTranslation(item?.nameT) : '' }}
                        </ng-container>
                        <ng-container *ngIf="multiString">
                            {{ value ? getTranslation(value) : '' }}
                        </ng-container>
                        <ng-container *ngIf="item?.doc_id">
                            {{ item?.doc_id }}
                        </ng-container>
                        <ng-container *ngIf="item?.label">
                            {{ item?.label?.name_t ? getTranslation(item?.label?.name_t) : item?.label?.name }}
                            <div class="small_text primary_color_text">
                                <div class="unt">{{ item | getProductUnit: globalRegistry?.systemData?.unitsMap }}</div>
                            </div>
                        </ng-container>
                        <span class="cls" (click)="removeItem(item.id)">
                            <i class="fa fa-times"></i></span>
                    </div>
                </ng-container>
            </span>
        </ng-container>
    </ng-container>
    <div class="chip_combo">
        <div class="input-group-append pl-1">
            <span class="input-group-text">
                <fap-round-button [backgroundColor]="'tertiary'"></fap-round-button>
             </span>
        </div>
        <div class="input-group w-100 position_absolute">
            <mat-form-field class="w-100">
                <mat-label>{{ label }}</mat-label>
                <mat-select #mys multiple panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" (infiniteScroll)="scrolledDown()" msInfiniteScroll required (selectionChange)="setItem($event)" [value]="selectedItems">
                    <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #typeFilter2 placeholder="Search" (keydown.enter)="filterItem.emit(typeFilter2.value); $event.stopPropagation(); mys.open()">
                    </mat-form-field>
                    <mat-label style="position: absolute; cursor: pointer;" (click)="filterItem.emit(typeFilter2.value)" [ngStyle]="addEnabled ? { 'right.px': '65' } : { 'right.px': '35' }">
                        <i class="fa fa-search f-20"></i>
                    </mat-label>
                    <div class="input-group-append pl-1" (click)="showAddModal.emit(true)" *ngIf="addEnabled">
                        <span class="input-group-text">
                            <fap-round-button [backgroundColor]="'tertiary'"></fap-round-button>
                         </span>
                    </div>
                </div>
                <div class="scroll_wrap" (scroll)="onScroll($event)">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let item of items | filter : typeFilter2.value" [value]="item.id">
                        <span class="mat-option-text">
                        <div class="name" [ngStyle]="getNgStyle()">
                            <ng-container *ngIf="item?.name && (!item?.name_t || !item.nameT)">
                                {{ item?.name }}
                            </ng-container>
                            <ng-container *ngIf="item?.label">
                                {{ item?.label?.name }}
                                <span class="small_text justify-content-between">
                                    <div class="unt">{{ item | getProductUnit: globalRegistry?.systemData?.unitsMap }}</div>
                                    <div class="producer">{{ item?.label?.producer?.name }}</div>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="(item?.name_t && !item.name) || (item?.name_t && item.name)">
                                {{ item?.name_t ? getTranslation(item?.name_t) : '' }}
                            </ng-container>
                            <ng-container *ngIf="item?.doc_id">
                                {{ item?.doc_id }}
                            </ng-container>
                            <ng-container *ngIf="item?.product">
                                {{ item?.product?.label?.name_t ? getTranslation(item?.product?.label?.name_t) : item?.product?.label?.name }}
                            </ng-container>

                            <span class="small_text" *ngIf="showFieldUniqueId">{{ item?.uniqueid }}</span>
                        </div>
                        <div class="actions" *ngIf="isEditable || isDeleteable">
                            <i class="fa fa-edit" *ngIf="isEditable" (click)="editItem.emit(item); $event.stopPropagation()"></i>
                            <i class="fa fa-trash" *ngIf="isDeleteable" (click)="deleteItem.emit(item?.id); $event.stopPropagation()"></i>
                        </div>
                        </span>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
</div>
</ng-container>