import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import {
  ApexAxisChartSeries,
  ApexFill,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexStroke,
  ApexMarkers,
} from "ng-apexcharts";
import { SensorModel } from "../../../core/models/sensor/sensor.model";
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { Subscription } from 'rxjs';
import { ObjectModel } from "../../../core/services/api/objects/object.service";
import { UnitTypeModel } from "../../../core/services/api/unit-type/unit-type.service";
import { NavService } from "../../services/nav.service";
import { LocalStorageService } from "../../../core/services/localStorage/local-storage.service";
import { CacheResolverService } from "../../../core/services/api/cache/cache-resolver.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: any;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  markers: ApexMarkers;
  colors: string[];
  fill: ApexFill;
  forecastDataPoints: any;
  legend: ApexLegend;
};

@Component({
  selector: 'graph-range-widget',
  templateUrl: './graph-range-widget.component.html',
  styleUrls: ['./graph-range-widget.component.scss']
})
export class GraphRangeWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];

  @Output()
  public emitShow:EventEmitter<boolean> = new EventEmitter<boolean>();

  public chartOptions: Partial<ChartOptions>;
  public localSensors = [];
  public finalSensorList = [];
  public loadedData = [];
  public newData;
  public seriesData = [];
  public listDate = [];
  public fromDate;
  public toDate;
  public agg;
  public toggleMain = false;
  public toggleSub = false;
  public cssClass = '';
  public randomId;
  public subscriptions: Array<Subscription> = [];
  public dates =[];
  public translatedNames: any = [];
  public langString: string;
  // apiCalled = false;
  public isLoading = false;
  public noData = false;

  constructor(public globalRegistry:GlobalRegistryService,public sensorService:SensorService, public navService: NavService, private localStorageService: LocalStorageService, private cacheService: CacheResolverService) {
    this.chartOptions = {
      series: [
        {
          name: "New York Temperature",
          data: [
            {
              x: "Jan",
              y: [-2, 4]
            },
            {
              x: "Feb",
              y: [-1, 6]
            },
            {
              x: "Mar",
              y: [3, 10]
            },
            {
              x: "Apr",
              y: [8, 16]
            },
            {
              x: "May",
              y: [13, 22]
            },
            {
              x: "Jun",
              y: [18, 26]
            },
            {
              x: "Jul",
              y: [21, 29]
            },
            {
              x: "Aug",
              y: [21, 28]
            },
            {
              x: "Sep",
              y: [17, 24]
            },
            {
              x: "Oct",
              y: [11, 18]
            },
            {
              x: "Nov",
              y: [6, 12]
            },
            {
              x: "Dec",
              y: [1, 7]
            }
          ]
        }
      ],
      chart: {
        height: 350,
        type: "rangeArea"
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "New York Temperature (all year round)"
      },
      markers: {
        hover: {
          sizeOffset: 5
        }
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val + "°C";
          }
        }
      }
    };
  }

  ngOnInit() {
    this.randomId = Math.random();
    this.getFromDate();
    this.getToDate();
    this.getAgg();
   
    this.subscriptions.push(this.sensorService.getName.subscribe(res => {
      if(res) {
        this.seriesData = [];
        this.loadedData = [];
        this.getRealTimeData();
        // this.apiCalled = false;
      }
    }));
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.localStorageService.watchTimezone().subscribe(data => {
          this.getRealTimeData();
        })
  }

  getFromDate() {
    this.subscriptions.push(this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    }));
  }

  getToDate() {
    this.subscriptions.push(this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    }));
  }

  getAgg() {
    this.subscriptions.push(this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    }));
  }

  getTranslation(translation) {
    const t = this.translatedNames.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
    }
}

  ngOnDestroy () {
    this.subscriptions.forEach(e => {
      e.unsubscribe();
    });
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  showMenu() {
    this.toggleSub = !this.toggleSub
    this.getRealTimeData();
    if(this.toggleSub == true) {
      this.chartOptions = {...this.chartOptions, ...{
        chart: {
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: true,
            }
          },
        },
        legend: {
          show: false,
        }
      }};
    } else {
      this.chartOptions = {...this.chartOptions, ...{
        chart: {
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            }
          },
        },
        legend: {
          show: false,
        }
      }};
    }
  }

  hideMain() {
    this.toggleMain = false
  }

  ngOnChanges() {
    this.getRealTimeData();
  }

  public getRealTimeData() {
    // if (!this.apiCalled) {
      this.isLoading = true;
    this.loadedData = [];
    this.seriesData = [];
    // const options = this.widget.config['settings']['options'];
    console.log(this.widget);
    this.cssClass = this.widget.config['sizeClass'];
      setTimeout(()=> {
        const sensors: Array<SensorModel> = this.widget.config["settings"]["options"].map(
          (sensor: { sensor: SensorModel }) => sensor?.sensor
        );
        const sensorIds = sensors.map((sensor) => sensor.id);
        let url = this.sensorService.getUrl('adv_drill_data/sensors='+sensorIds.toString()+'&from='+this.fromDate+'&agg_by='+this.agg+'&to='+this.toDate+'&agg_data=mean,sum,min,max&compare=0');
        this.subscriptions.push(this.sensorService.advdrillData({ sensors: sensorIds.toString(), from:this.fromDate,agg_by: this.agg, to:this.toDate, agg_data:'mean,sum,min,max', compare:0}).subscribe(data1 => {
          console.log(data1)
          this.cacheService.delete(url);
          const Newdata = data1.results;
          const data = Newdata['0'];
          console.log(data);
          if(data.labels.length === 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
        setTimeout(()=> {
          const objects = Object.values(this.objects);
          const unitTypes = Object.values(this.unitTypes);
          const arr = Object.keys(data).map(function (key) {
            return { ...data[key], sensor: key };
          });
          arr.pop();
          const datesData = Object.keys(data).map(function (key) {
            return { [key]: { ...data[key]} };
          });
          const dates = datesData.pop();
          const datesArr = Object.values(dates.labels);
          const seriesDates = [];
          // function toDateTime(secs) {
          //   var t = new Date(1970, 0, 1); // Epoch
          //   t.setSeconds(secs).toLocaleString('en-US');
          //   return t;
          // }
          datesArr.forEach((element: any) => {
            const date = new Date(element * 1000);
            seriesDates.push(date.toLocaleString('en-US'));
          });
          console.log(seriesDates);
          this.dates = seriesDates
          this.widget.config["settings"]["options"] = this.widget.config["settings"]["options"].map((item) => {
            return {
              ...item,
              unitTypes: unitTypes.filter(el => el.id === item.sensor.unitType),
              objects: objects.filter(el => el.id === item.sensor.object_id),
          }
          });

        //  arr.forEach(element => {
        //    element = Object.values(element)
        //  });

        // console.log(arr);

         this.widget.config['settings']['options'] = this.widget.config['settings']['options'].map((item) => {
          return {
            ...item,
            data: arr.filter(o1 => o1.sensor == item.sensor.id)
          };
        });
        // console.log(this.widget.config['settings']['options']);
          
          for (let i = 0; i < this.widget.config['settings']['options'].length; i++) {
            const series = this.widget.config["settings"]["options"].map((type) => {
              console.log(type);
              return {
                name: type.data[0].name +'(' + type.unitTypes[0].unit + ')',
                type: 'rangeArea',
                data: {x: 'Jan', y: [1, 10]}
              };
            });
            this.seriesData = series;
          }

          this.seriesData.forEach(element => {
            element.name = element.name.replace(/null/g, "");
          });

          // console.log(this.seriesData);
          // console.log(this.widget.config['settings']['options']);
          // this.chartOptions.series = this.seriesData;
          // this.chartOptions.colors = this.widget.config['settings']['options'].map((type)=>type.color);
          
          // if(this.chartOptions.series.length === 1) {
          //   this.chartOptions.chart.type = this.seriesData[0].type;
          // }

          // console.log(this.chartOptions);
          // const sameYear = false;
          // this.chartOptions.xaxis.categories = this.dates;
               
              //  if(this.agg == 'hour' && !sameYear) {
              //   this.chartOptions.xaxis.labels = {
              //         formatter: val => moment.utc(val).local().format('DD MMM, HH') + 'h'
              //   }
              // }
              // if(this.agg == 'hour' && sameYear) {
              //   this.chartOptions.xaxis.labels = {
              //         formatter: val => moment.utc(val).local().format('DD MMM YYYY, HH')
              //   }
              // }
              // if(this.agg == 'day' && !sameYear) {
              //   this.chartOptions.xaxis.labels = {
              //     formatter: val => moment.utc(val).local().format("DD MMM")
              //   }
              // }
              // if(this.agg == 'day' && sameYear) {
              //   this.chartOptions.xaxis.labels = {
              //     formatter: val => moment.utc(val).local().format('DD MMM YYYY')
              //   }
              // }
              // if(this.agg == 'month' && sameYear) {
              //   this.chartOptions.xaxis.labels = {
              //     formatter: val => moment.utc(val).local().format('MMM YYYY')
              //   }
              // }
              // if(this.agg == 'month' && !sameYear) {
              //   this.chartOptions.xaxis.labels = {
              //     formatter: val => moment.utc(val).local().format('MMM YYYY')
              //   }
              // }
           console.log(this.widget.config['settings']['options']);
          // function parentWidth(elem) {
          //   return elem.parentElement.clientHeight;
          // }
        //  this.chartOptions.chart.height = parentWidth(document.getElementById('parent'+this.randomId));
          
        })
      }))
        this.sensorService.setName(false);
        this.isLoading = false;
      }, 1000)
    // }
    // this.apiCalled = true;
  }

  public getLotName(lotId:number=this.widget.objectId):string{
    if(lotId != 0) {
      return this.globalRegistry.systemData.lots.find(lot=>lot.id===lotId).name;
    } else {
      return ''
    }
  }

}
