import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewUnitModel } from '../../../../core/models/units/new-unit-model';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { UnitModel } from '../../../../core/models/units/unit.model';
import { FapModalComponent } from '../../../../shared/partials';
import { ConfirmModalService } from '../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitService } from '../../../../core/services/api/units/unit.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
import { CropService } from '../../../../core/services/api/crop/crop.service';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { ProductUsageTypeModel } from '../../../../core/models/stock/product_usage_type.model';

@Component({
  selector: 'fap-add-edit-treatment',
  templateUrl: './add-edit-treatment.component.html',
  styleUrls: ['./add-edit-treatment.component.scss']
})
export class AddEditTreatmentComponent implements OnInit, OnChanges {

  public addEditTreatmentForm: FormGroup;
  public applyValue: NewUnitModel;
  public dilutionValue: NewUnitModel;
  public defaultApplyUnit;
  public defaultDilutionUnit;
  public defaultRepauseUnit;
  public selectedUnit: UnitModel;
  public parentUnit: UnitModel;
  public selectedUnitId: number;
  public langString: string;
  public subscription: Subscription = new Subscription();
  public label;
  public show: boolean = false;
  public labelId
  public translationLabel = '';

  @Input() public treatment: any;
  @Input() public treatmentId: number = -1;
  @Input() public productUsageTypes: Array<ProductUsageTypeModel> = [];
  @Input() public formTypes = [];

  @Output() public addedTreatment: EventEmitter<any> = new EventEmitter();
  @Output() public updatedTreatment: EventEmitter<any> = new EventEmitter();
  @Output() public closeModal: EventEmitter<any> = new EventEmitter();
  @Output() public refreshProductUsageTypes: EventEmitter<any> = new EventEmitter();

  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  @ViewChild('productsModal')
  public productsModal: FapModalComponent;
  @ViewChild('addEditProductModal')
  public addEditProductModal: FapModalComponent;
  @ViewChild('addEditProductUsageTypeModal')
  public addEditProductUsageTypeModal: FapModalComponent;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

  public productUsageType = null;
  public productUsageTypeId: any;
  public productUsageTypeForm: FormGroup;
  public objectId: number = null;
  public entityInfo = null;
  public translation;
  public closeCombo: boolean = true;
  public selectedTypes = [];
  public descriptions = [];
  
  public addProductUsageTypeModalButtonPrimaryInterface: FapModalButtonInterface;
  public addProductUsageTypeModalButtonSecondaryInterface: FapModalButtonInterface;

  constructor(private formBuilder: FormBuilder, public globalRegistry: GlobalRegistryService, private confirmModalService: ConfirmModalService, private translateService: TranslateService, public unitService: UnitService, private toastyService: ToastrService, private navService: NavService, private cropService: CropService, private stockService: StockService, private typeService: TypesService, private cacheService: CacheResolverService, private translate: TranslateService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
          this.langString = lang;
        }
    }));
    this.defaultApplyUnit = this.getDefaultUnit('UNIT_CN__AREA', 'UNIT_N__HECTARE');
    this.defaultDilutionUnit = this.getDefaultUnit('UNIT_CN_VOLUME', 'UNIT_N_LITER');
    this.defaultRepauseUnit = this.getDefaultUnit('UNIT_CN_TIME', 'UNIT_N_HOUR');
    this.initAddEditTreatmentForm();
    this.initProductUsageTypeForm();
    this.initAddNoteTypeModalButtons();
    if(this.treatmentId == -1) {
      this.applyValue = {unit: this.defaultApplyUnit, value: 10};
      this.dilutionValue = {unit: this.defaultDilutionUnit, value: 10};
      this.initAddEditTreatmentForm();
    }
  }

  public initAddNoteTypeModalButtons(): void {
    const _this: AddEditTreatmentComponent = this;

    this.addProductUsageTypeModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.productUsageTypeSubmit();
        },
        text: this.translateService.instant('save')
    };

    this.addProductUsageTypeModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addEditProductUsageTypeModal.hideModal();
        },
        text: this.translateService.instant('cancel')
    };
}

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
            p1.toUpperCase()
        );
        camelCaseObj[camelCaseKey] =
            obj[key] instanceof Object
                ? this.convertKeysToCamelCase(obj[key])
                : obj[key];
    }
    return camelCaseObj;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('treatment') && this.treatment) {
      this.label = this.treatment.label
      console.log(this.treatment);
      this.initAddEditTreatmentForm();
      this.descriptions = this.treatment.description;
    }
    if(changes.hasOwnProperty('treatmentId') && this.treatmentId) {
      if(this.treatmentId == -1) {
        this.treatment = null;
        this.label = null;
        this.applyValue = {unit: this.defaultApplyUnit, value: 10};
        this.dilutionValue = {unit: this.defaultDilutionUnit, value: 10};
        // this.addEditTreatmentForm.reset();
        // this.addEditTreatmentForm.clearValidators();
        this.initAddEditTreatmentForm();
      }
    }
  }

  getDefaultUnit = (unitNameT, childNameT) => {
    const unit = this.globalRegistry.systemData.units.find(u => u.nameT === unitNameT);
    if (unit) {
        const child = unit.children.find(element => element.nameT === childNameT);
        return child ? child.id : null;
    }
    return null;
};

  public initAddEditTreatmentForm(): void {
    this.addEditTreatmentForm = this.formBuilder.group({
        label: [this.treatment ? this.treatment.label.id : null],
        apply: [this.treatment ? this.treatment.apply : null],
        type: [this.treatment ? this.treatment.type : null],
        apply_unit: [this.treatment ? this.treatment.apply_unit : this.defaultApplyUnit],
        dilution: [this.treatment ? this.treatment.dilution : null],
        dilution_unit: [this.treatment ? this.treatment.dilution_unit : this.defaultDilutionUnit],
        quantity_min: [this.treatment ? this.treatment.quantity_min : 1],
        quantity_max: [this.treatment ? this.treatment.quantity_max : 10],
        quantity_unit: [this.treatment ? this.treatment.quantity_unit : this.defaultDilutionUnit],
        repause_min: [this.treatment ? this.treatment.repause_min : 1],
        repause_max: [this.treatment ? this.treatment.repause_max : 10],
        repause_unit: [this.treatment ? this.treatment.repause_unit : this.defaultRepauseUnit],
        reaction_min: [this.treatment ? this.treatment.reaction_min : 1],
        reaction_max: [this.treatment ? this.treatment.reaction_max : 10],
        reaction_unit: [this.treatment ? this.treatment.reaction_unit : this.defaultRepauseUnit],
    });
    if(this.treatment) {
      this.applyValue = {unit: this.treatment.apply_unit ? this.treatment.apply_unit : this.defaultApplyUnit, value: this.treatment.apply ? this.treatment.apply : 1};
      this.dilutionValue = {unit: this.treatment.dilution_unit ? this.treatment.dilution_unit : this.defaultDilutionUnit, value: this.treatment.dilution ? this.treatment.dilution: 1};
      this.selectedTypes = this.treatment.type
    } else {
      this.selectedTypes = [];
    }
  }

  updateTypes(types) {
    console.log(types);
    this.addEditTreatmentForm.get('type').setValue(types);
    this.selectedTypes = types;
  }

  validateRepauseMinMax() {

  }

  validateQuantityMinMax() {

  }

  public selectUnit(unit?: UnitModel, parent?:  UnitModel): void {
    if(unit?.isDefault) {
        return;
    }

    this.selectedUnit = unit;

    this.parentUnit = parent ? parent : null;
    this.addEditUnitModal.showModal();
  }

  public onUnitAction(unitId: number): void {
    this.selectedUnitId = unitId;
    this.addEditUnitModal.hideModal();
  }

  public onDeleteUnit(unit: UnitModel): void {
    if(unit.isDefault) {
        return;
    }

    this.confirmModalService.openConfirmModal(
        this.translateService.instant('confirmDelete'),
        this.translateService.instant('units.deleteUnitConfirmMessage')
    );
    this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
        if (confirmed) {
            this.deleteUnit(unit.id);
        }
    });
  }

  public deleteUnit(unitId: number): void {
      this.unitService.deleteUnit(unitId).subscribe({
        next: (): void => {
          this.toastyService.success(this.translateService.instant('units.unitDeleteSuccessfull'));
          this.globalRegistry.reloadUnits();
        },
        error: (): void => {
          this.toastyService.error(this.translateService.instant('units.UnitDeleteError'));
        }
      });
    }

    getTranslation(translation) {
      const t = this.globalRegistry.systemData.translations.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
          if(t[0][this.langString] === null || t[0][this.langString] === '') {
            return translation
          } else {
            return t[0][this.langString];
          }
        } else {
          return translation
      }
    }

    updateDescription(ev) {
      console.log(ev);
      this.upsert(this.descriptions, ev);
      console.log(this.descriptions);
    }

    upsert(array, newObj) {
      const index = array.findIndex(obj => obj.id === newObj.id);
      if (index !== -1) {
          array[index] = newObj;
      } else {
          array.push(newObj);
      }
    }

    public addProductAction(ev) {
      console.log(ev);
      this.label = ev;
      this.addEditTreatmentForm.controls['label'].setValue(ev.id);
      this.addEditProductModal.hideModal();
    }
    
    public updateProductAction(ev) {
      console.log(ev)
      this.addEditTreatmentForm.controls['label'].setValue(ev.id);
      this.addEditProductModal.hideModal();
    }

    assignProduct(label) {
      let labelId = label.id
      this.stockService.getProductLabel(labelId).subscribe(data => {
        if(data) {
          this.label = data.body.results;
          this.labelId = this.label.id;
          this.addEditTreatmentForm.get('label').setValue(label.id);
          this.hideProducts();
        }
      }, (error) => {
        this.toastyService.error(error.error.results.error)
      })
    }
    
    hideProducts() {
      this.productsModal.hideModal();
      this.show = false;
    }

  submitTreatment() {
    console.log(this.addEditTreatmentForm.value);
    console.log(this.treatmentId);
    let descriptionIds = [];
    this.descriptions.forEach(desc => {
      descriptionIds.push(desc.id);
    });
    let body = {
      label: this.addEditTreatmentForm.value.label,
      apply: this.applyValue.value,
      type: this.addEditTreatmentForm.value.type,
      apply_unit: this.applyValue.unit,
      dilution: this.dilutionValue.value,
      dilution_unit: this.dilutionValue.unit,
      repause_min: this.addEditTreatmentForm.value.repause_min,
      repause_max: this.addEditTreatmentForm.value.repause_max,
      repause_unit: this.addEditTreatmentForm.value.repause_unit,
      quantity_min: this.addEditTreatmentForm.value.quantity_min,
      quantity_max: this.addEditTreatmentForm.value.quantity_max,
      quantity_unit: this.addEditTreatmentForm.value.quantity_unit,
      reaction_min: this.addEditTreatmentForm.value.reaction_min,
      reaction_max: this.addEditTreatmentForm.value.reaction_max,
      reaction_unit: this.addEditTreatmentForm.value.reaction_unit,
      description: descriptionIds
    }
    if(this.treatmentId === -1) {
      this.createTreatment(body);  
    } else {
      this.updateTreatment(body);  
    }
  }

  createTreatment(body) {
    this.stockService.createProductUsage(body).subscribe(data => {
      console.log(data.model);
      this.addedTreatment.emit(data.body.results);
    }, (error) => {
      this.toastyService.error(error.error.results.error);
    })
  }

  updateTreatment(body) {
    this.stockService.updateProductUsage(this.treatmentId, body).subscribe(data => {
      console.log(data.model);
      this.updatedTreatment.emit(data.body.results);
    }, (error) => {
      this.toastyService.error(error.error.results.error);
    })
  }

  public productUsageTypeSubmit(){
    if(this.productUsageTypeForm.invalid) {
        Object.keys(this.productUsageTypeForm.controls).forEach((controlName: string) =>
            this.productUsageTypeForm.controls[controlName].markAsTouched()
        );
        return;
    }
    if(this.productUsageTypeId){
        this.editProductUsageType(this.productUsageTypeForm.value, this.productUsageTypeId);
        this.addEditProductUsageTypeModal.hideModal();
        
    } else {
        this.addProductUsageType(this.productUsageTypeForm.value)
        this.addEditProductUsageTypeModal.hideModal();
        
    }
  }

  public getFormTypeById(typeId: number) {
    if(!typeId) return
    const url = this.typeService.getUrl('form/');
    this.cacheService.delete(url+typeId);
    this.typeService.getFormTypeById(typeId).subscribe(data => {
      console.log(data);
      this.entityInfo = data.body.results;
    })
  }

setFormType(formType) {
    this.productUsageTypeForm.get('entity_form').setValue(formType.id);
    if(this.productUsageType.formType === formType.id || this.productUsageType.formType == null) {
        this.getFormTypeById(formType.id);
    } else {
        this.confirmModalService.openConfirmModal(
            this.translate.instant('forms.changeFormType'),
            this.translate.instant('forms.changeFormTypeMessage'));
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    if(formType.id) {
                        this.objectId = formType.id;
                        this.productUsageTypeForm.get('entity_form').setValue(formType.id);
                        this.getFormTypeById(formType.id);
                    }
                }
            }
        );
    }
  }

  onAddUpdateTranslation(translation) {
      console.log(translation);
      this.translation = translation;
      this.productUsageTypeForm.controls['name_t'].setValue(translation.id);
      this.productUsageTypeForm.controls['name'].setValue(translation[this.langString]);
      this.addEditTranslationsModal.hideModal();
  }

  public addProductUsageType(productUsageType: FormData): void {
          this.typeService.addProductUsageType(productUsageType).subscribe((data) => {
              this.toastr.success(this.translate.instant('productsUsage.createUsageType'));
              this.productUsageTypes.unshift(data.model);
              console.log(data)
              if(productUsageType['entity_form']) {
                  this.objectId = productUsageType['entity_form'];
                  this.getFormTypeById(productUsageType['entity_form']);
              }
              this.addEditTreatmentForm.get('type').setValue(data.model.id);
              this.closeCombo = true;
              this.refreshProductUsageTypes.emit();
          }, (error) => {
              this.toastr.error(error.error.results.error);
          });    
      }

  public editProductUsageType(productUsageType: FormData, productUsageTypeId: number): void {
              this.typeService.updateProductUsageType(productUsageType, productUsageTypeId).subscribe((data) => {
              this.toastr.success(this.translate.instant('productsUsage.updateUsageType'));
              const index = this.productUsageTypes.findIndex(type => type.id === productUsageTypeId);
              if (index !== -1) {
                  this.productUsageTypes[index] = data.model;
                  console.log(this.productUsageTypes);
              }
              this.addEditTreatmentForm.get('type').setValue(data.model.id);
              if(productUsageType['entity_form']) {
                  this.objectId = productUsageType['entity_form'];
                  this.getFormTypeById(productUsageType['entity_form']);
              }
              this.closeCombo = true;
              this.refreshProductUsageTypes.emit();
          }, (error) => {
              this.toastr.error(error.error.results.error);
          });    
    }

  public deleteProductUsageType(productUsageTypeId: number): void {
      this.typeService.deleteProductUsageType(productUsageTypeId).subscribe(() => {
          this.toastr.success(this.translate.instant('productsUsage.deleteProductUsageType'));

          this.refreshProductUsageTypes.emit();
      });     
  }

verifyFormType(type) {    
    if(type.formType) {
        this.confirmModalService.openConfirmModal(
        this.translate.instant('forms.changeFormType'),
        this.translate.instant('forms.changeFormTypeMessage'));
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                if(type.formType) {
                    this.objectId = type.formType;
                    this.addEditTreatmentForm.get('type').setValue(type.id);
                    this.getFormTypeById(type.formType)
                }
            }
        });
    } else {
        this.entityInfo = null;
        this.objectId = null;
    }
  }

  productUsageTypeEdit(productUsageType){
      this.productUsageTypeId= productUsageType.id;
      this.productUsageType = productUsageType;
      console.log(this.productUsageType)
      this.productUsageTypeForm.controls['name'].setValue(productUsageType.name);
      this.productUsageTypeForm.controls['name_t'].setValue(productUsageType.nameT);
      this.productUsageTypeForm.controls['color'].setValue(productUsageType.color);
      this.showProductUsageTypeModal();
  }

  public showProductUsageTypeModal(newCheck?:boolean): void {
    if(newCheck) {
        this.productUsageType = null
        this.productUsageTypeId = null;
        this.initProductUsageTypeForm();
    }
    this.addEditProductUsageTypeModal.showModal();
  }

  public initProductUsageTypeForm() {
    this.productUsageTypeForm = this.formBuilder.group({
        name: [this.productUsageType ? this.productUsageType.name : '', Validators.compose([
            Validators.required,
        ])],
        name_t: [this.productUsageType ? this.productUsageType.nameT : ''],
        color: [this.productUsageType ? this.productUsageType.color : ''],
        entity_form: [this.productUsageType ? this.productUsageType.formType : null],
    });
  }

  productUsageTypeDelete(productUsageType){
      this.deleteProductUsageType(productUsageType.id);           
  }

  addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
    this.translationLabel = label;
    const name = this[inputElement].nativeElement.value;
    const type: any = this.convertKeysToCamelCase(translationTarget);
    console.log(name);
    console.log(type);
    if (name === null || name === '' || type.nameT === null) {
        this.translation = null;
        popup.showModal();
    } else {
        for (const translation of this.globalRegistry.systemData.translations) {
            if (type && translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                popup.showModal();
                return;
            }
        }

        if (Object.keys(type).length === 0 && name) {
            const newTranslation = this[form].controls['name_t'].value;
            for (const translation of this.globalRegistry.systemData.translations) {
                if (translation.id === newTranslation) {
                    this.translation = translation;
                    console.log(this.translation);
                    popup.showModal();
                    return;
                }
            }
        }
    }
  }

}
