import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { ActivityModel } from '../../../core/models/activity/activity.model';
import { ImageModel } from '../../../core/models/company/image.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { CropService } from '../../../core/services/api/crop/crop.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill
} from "ng-apexcharts";
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { CropModel } from '../../../core/models/crops/crop.model';
import { ActivityService } from '../../../core/services/api/activity/activity.service';
import { environment } from '../../../../environments/environment';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'fap-weed-control-widget',
  templateUrl: './fap-weed-control-widget.component.html',
  styleUrls: ['./fap-weed-control-widget.component.scss']
})
export class FapWeedControlWidgetComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input()
  public widget: WidgetModel;
  
  @Input()
  public crops: Array<CropModel> = [];
  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
  public lotId;
  public activity:ActivityModel;
  public images: Array<ImageModel> = [];
    public translatedNames: any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public toggleMain = false;
    public toggleSub = false;
    public selectedWidget;
    public startDate;
    public event;
    public sensorId: number;
    public error = false;
    public herbicideData;
    public seriesData = [];
    public crop: CropModel;
    public randomId;
    public mediaUrl = environment.mediaUrl;

  constructor(public companyService: CompanyService, public navService: NavService, public globalRegistry: GlobalRegistryService, public toastyService: ToastrService, public translate: TranslateService, public cropService: CropService, public sensorService: SensorService, public activityService: ActivityService) {
    this.chartOptions = {
      series: this.seriesData,
      chart: {
        type: "bar",
        toolbar: {
          show: false,
          tools: {
            download: false,
          }
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: false,
        formatter: function(val) {
          return val + "%";
        },
      },

      xaxis: {
        type: 'category',
        position: "bottom",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      
      yaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#2b674c",
          },
          labels: {
            show: false,
            formatter: function(val) {
              return val + "%";
            },
            style: {
              colors: '#333'
            }
          },
          title: {
            text: "",
            style: {
              color: "#2b674c",
              fontSize: '9px'
            },
          },
          tooltip: {
            enabled: false,
          },
        },
    };
   }

  ngOnInit(): void {
    this.lotId = this.widget.objectId;
    console.log(this.widget.config);
    if(this.widget.config['activity']) {
    this.activityService.getActivity(this.widget.config['activity']).subscribe(data => {
      this.activity = data.model;
      if(this.activity.started!=null) {
        moment.locale('en', {
          relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s:  '3s',
            ss: '%ss',
            m:  'a minute',
            mm: '%d minutes',
            h:  'a hour',
            hh: '%d hours',
            d:  'a day',
            dd: '%d days',
            M:  'a month',
            MM: '%d months',
            y:  'an year',
            yy: '%d years'
          }
        });
        const dstring = moment.utc(this.activity.started).local();
        this.startDate = moment(dstring).fromNow();
        console.log(this.startDate);
        if(moment(moment.now()).isAfter(moment.utc(this.activity.started).local())) {
          this.event = 'Applied:'
        } else {
          this.event = 'Planned:'
        }
        console.log(this.event);
      }

      this.subscription.add(
        this.cropService.getHerbicideEfficacity(this.activity.id).subscribe((data) => {
          if(data) {
            console.log(data.body.results[0]);
            this.herbicideData = data.body.results[0];
            console.log(this.herbicideData);
            this.sensorId = this.herbicideData.value.sensor;
            // let categories = [];
            // let chartData = [];
            if(this.herbicideData.evolution.data) {
            this.herbicideData.evolution.data.forEach(element => {
              // categories.push(element.timestamp);
              // chartData.push(element.value_float);
              const series = {
                x: moment(element.timestamp).format('DD MMM'), y: element.value_float
              };
                  this.seriesData.push(series);
            })
          }
            setTimeout(() => {
              // console.log(categories);
              console.log(this.seriesData);
              this.chartOptions.series = [{data: this.seriesData, name: 'Efficacity'}];
            },100)
            this.error = false;
          }
        }, error => {
          console.log(error);
          this.error = true;
          this.chartOptions.series = [];
          return
        }
        )
      );
    })
    }
    this.randomId = 'parent' + Math.random();
    
    this.langString = localStorage.getItem('language');
    this.subscription.add(
        this.navService.getCurrentLanguage.subscribe((lang) => {
            if (lang) {
                this.langString = lang;
            }
        })
    );
    this.translatedNames = this.globalRegistry.systemData.translations;
    
    
          const crop = this.crops.filter((c) => c.lot === this.lotId);
          this.crop = crop[0];
          console.log(this.crop);
     const elem = document.getElementById(this.randomId);
     console.log(elem);
  function parentWidth(elem) {
    console.log(elem);
    return elem.clientHeight;
  }
 this.chartOptions.chart.height = parentWidth(document.getElementById(this.randomId));
}

getImage(imageId) {
  this.companyService.getCompanyImage(imageId).subscribe(data => {
    if(data) {
      return data.body.results.image
    } else {
      return '../../../../assets/images/image_placeholder.png'
    }
  })
}

getTranslation(translation) {
    const t = this.translatedNames.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
    }
}

public getLotName(lotId: number = this.widget.objectId): string {
  const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
  return lot ? lot.name : '';
}

hideMain() {
    this.toggleMain = false;
}

toggleMenu() {
    this.toggleMain = !this.toggleMain;
}

ngOnDestroy(): void {
    this.subscription.unsubscribe();
}

showModal(widget): void {
  this.selectedWidget = widget;
  this.confirmPopup.showModal();
}

closeModal(widget): void {
  this.selectedWidget = widget;
  this.confirmPopup.hideModal();
}

verifyPayment() {
this.toastyService.warning(
  this.translate.instant("widget.contactSupport")
)
}

ngOnChanges(changes: SimpleChanges): void {
  if (Object.prototype.hasOwnProperty.call(changes, 'activity')) {
    console.log(changes);
  }
}

}
