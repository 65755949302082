import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BillModel } from './../../../models/stock/bill.model';
import { ResponseModel } from '../../../models/response.model';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { StockItemModel } from '../../../models/stock/stock-item.model';
import { StockOverviewModel } from '../../../models/stock/stock-overview.model';
import { StockIncomeInterface } from '../../../interfaces/stock/stock-income.interface';
import { StockOutcomeInterface } from '../../../interfaces/stock/stock-outcome.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { StockResourceModel } from '../../../models/stock/stock-resource.model';
import { ProductModel } from '../../../models/stock/product.model';
import { DocumentItemModel } from '../../../models/stock/document-item.model';
import { IngredientModel } from '../../../models/stock/ingredient.model';
import { IngredientInterface } from '../../../interfaces/ingredient/ingredient.interface';
import { DocumentModel } from '../../../models/stock/document.model';
import * as CryptoJS from 'crypto-js'
import { CacheResolverService } from '../cache/cache-resolver.service';
import { TreatmentModel } from '../../../models/diseases/treatment.model';
import { ProductLabelModel } from '../../../models/stock/product-label.model';

@Injectable()
export class StockService extends FapRestBaseService {
    private outcomeObj = new BehaviorSubject(null);
    
    getOutcomeObj = this.outcomeObj.asObservable();

    constructor(tosterService: ToastrService,
                private _http: HttpClient, public cacheService: CacheResolverService) {
        super(tosterService, _http, '/stock/');
    }

    public setOutcomeObj(data) {
        this.outcomeObj.next(data);
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getStockItem(params?: {}): Observable<ResponseModel<StockItemModel[]>> {
        this.cacheService.delete(this.url + 'stock_item/');
        return this.mapRequest<StockItemModel[]>(
            this._http.get(this.url + 'stock_item/', {
                headers:  FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            StockItemModel,
            true
        ).pipe(take(1));
    }

    public getStockOverview(): Observable<ResponseModel<StockOverviewModel[]>> {
        this.cacheService.delete(this.url + 'overview/');
        return this.mapRequest<StockOverviewModel[]>(
            this._http.get(this.url + 'overview/',
                FapAPIRequestOptions.withTokenRequestOptions),
                StockOverviewModel,
            true
        ).pipe(take(1));
    }

    public getTransactions(resourceEntryId?: {}): Observable<ResponseModel<any>> {
        this.cacheService.delete(this.url + 'transactions/?resource_entry=' + resourceEntryId);
        return this.mapRequest<any>(
            this._http.get(
                this.url + 'transactions/?resource_entry=' + resourceEntryId, {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                }),
            false
        ).pipe(take(1));
    }

    public addStockIncome(stockIncome: StockIncomeInterface): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'farm_stock/stock_income/',
                this.camelCaseModelToSnakeCaseJson(stockIncome),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public addStockOutcome(stockOutcome: StockOutcomeInterface): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'farm_stock/stock_outcome/',
                this.camelCaseModelToSnakeCaseJson(stockOutcome),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public getExchange(params?: {}): Observable<ResponseModel<{}>> {
        return this.mapRequest<any[]>(
            this._http.get(
                this.url + 'exchange/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            false
        ).pipe(take(1));
    }

    public addExchange(params: {}): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'exchange/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
        ).pipe(take(1));
    }

    public addOffer(params: {}): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'offer/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
        ).pipe(take(1));
    }

    public getBills(params?: {}): Observable<ResponseModel<BillModel[]>> {
        return this.mapRequest<BillModel[]>(
            this._http.get(
                this.url + 'bills/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                BillModel,
            true
        ).pipe(take(1));
    }

    public getBill(params?: {}): Observable<ResponseModel<BillModel>> {
        return this.mapRequest<BillModel>(
            this._http.get(
                this.url + 'bills/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                BillModel,
            true
        ).pipe(take(1));
    }

    public getDocs(params?: {}): Observable<ResponseModel<DocumentModel[]>> {
        return this.mapRequest<DocumentModel[]>(
            this._http.get(
                this.url + 'docs/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                DocumentModel,
            true
        ).pipe(take(1));
    }

    public getDoc(docId: number): Observable<ResponseModel<DocumentModel>> {
        this.cacheService.delete(this.url + 'docs/' + docId + '/');
        return this.mapRequest<DocumentModel>(
            this._http.get(
                this.url + 'docs/' + docId + '/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                }),
                DocumentModel,
            false
        ).pipe(take(1));
    }

    public deleteDoc(docId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + 'docs/' + docId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public deleteDocItem(docItemId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + 'doc_item/' + docItemId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public getStockItemProducts(params?: {}): Observable<ResponseModel<StockItemModel[]>> {
        this.cacheService.delete(this.url + 'stock_items/');
        return this.mapRequest<StockItemModel[]>(
            this._http.get(
                this.url + 'stock_items/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                StockItemModel,
            true
        ).pipe(take(1));
    }

    public refreshStockItem(id, params?: {}): Observable<ResponseModel<{}>> {
        this.cacheService.delete(this.url + 'stock_items/'+id+'/');
        return this.mapRequest(
            this._http.get(
                this.url + 'stock_items/'+id+'/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                
            false
        ).pipe(take(1));
    }

    public getStockItems(params?: {}): Observable<ResponseModel<StockItemModel[]>> {
        this.cacheService.delete(this.url + 'stock_items/');
        return this.mapRequest<StockItemModel[]>(
            this._http.get(
                this.url + 'stock_items/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                StockItemModel,
            true
        ).pipe(take(1));
    }

    public getStockResourceEntry(id?: any): Observable<ResponseModel<StockResourceModel[]>> {
        this.cacheService.delete(this.url + 'stock_resources/?stockitem=' + id);
        return this.mapRequest<StockResourceModel[]>(
            this._http.get(
                this.url + 'stock_resources/?stockitem=' + id, {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                }),
            false
        ).pipe(take(1));
    }

    public getDevicesResourceEntries(params?: any): Observable<ResponseModel<StockResourceModel[]>> {
        return this.mapRequest<StockResourceModel[]>(
            this._http.get(
                this.url + 'stock_resources/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            false
        ).pipe(take(1));
    }

    public getStockResources(params?: any): Observable<ResponseModel<StockResourceModel[]>> {
        this.cacheService.delete(this.url + 'stock_resources/product='+params['product']+'&farm='+params['farm']);
        return this.mapRequest<StockResourceModel[]>(
            this._http.get(
                this.url + 'stock_resources/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            false
        ).pipe(take(1));
    }

    public getDocument(params?: {}): Observable<ResponseModel<DocumentItemModel[]>> {
        this.cacheService.delete(this.url + 'doc_item/document='+params['document']+'&type='+params['type']);
        return this.mapRequest<DocumentItemModel[]>(
            this._http.get(
                this.url + 'doc_item/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                DocumentItemModel,
            true
        ).pipe(take(1));
    }

    public createDocument(params: any): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.post(
                this.url + 'docs/', 
                params,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public updateDocument(params: any): Observable<ResponseModel<any[]>> {
        this.cacheService.delete(this.url + 'docs/' + params.id + '/');
        return this.mapRequest<any[]>(
            this._http.patch(
                this.url + 'docs/' + params.id + '/', 
                params,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public createDocumentItem(documentId, params: any, type?:number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.post(
                this.url + 'doc_item/?document=' + documentId + '&type='+ type, 
                params,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public updateDocumentItem(documentItemId, params: any): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.patch(
                this.url + 'doc_item/' + documentItemId, 
                params,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public getProducts(params?: {}): Observable<ResponseModel<ProductModel[]>> {
        return this.mapRequest<ProductModel[]>(
            this._http.get(this.url + 'products/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                })
                .pipe(map((x) => {
                    return this.decryptResponse(x['raw'])
                })), false).pipe(take(1))
    }

    decryptResponse(r) {
        const token = localStorage.getItem('auth_token').substring(0, 32);
        const _key = CryptoJS.enc.Utf8.parse(token);
        const decrypted = CryptoJS.AES.decrypt(
          r, _key, {
            keySize: 32,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);
          return JSON.parse(decrypted);
        }

    public getProduct(productId): Observable<ResponseModel<ProductModel>> {
        this.cacheService.delete(this.url + 'products/' + productId + '/');
        return this.mapRequest<ProductModel>(
            this._http.get(this.url + 'products/' + productId + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            ProductModel,
            false
        ).pipe(take(1));
    }

    public getProductLabel(productId): Observable<ResponseModel<ProductModel>> {
        this.cacheService.delete(this.url + 'product_labels/' + productId + '/');
        return this.mapRequest<ProductModel>(
            this._http.get(this.url + 'product_labels/' + productId + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            ProductModel,
            false
        ).pipe(take(1));
    }

    public getProductUsageById(productId): Observable<ResponseModel<any>> {
        this.cacheService.delete(this.url + 'product_usages/' + productId + '/');
        return this.mapRequest<any>(
            this._http.get(this.url + 'product_usages/' + productId + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            false,
            false
        ).pipe(take(1));
    }
    
    public addProduct(products: any): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'products/',
                this.camelCaseModelToSnakeCaseJson(products),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
            ).pipe(take(1));
    }

    public updateProduct(productId: any, product: ProductModel): Observable<ResponseModel<ProductModel>> {
        return this.mapRequest(
            this._http.patch(this.url + 'products/' + productId + '/',
                this.camelCaseModelToSnakeCaseJson(product),
                FapAPIRequestOptions.withTokenRequestOptions),
                ProductModel,
            false
        );
    }

    public deleteProduct(productId: number): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.delete(this.url + 'products/' + productId + '/',
                FapAPIRequestOptions.withTokenRequestOptions));
    }
        
    public getProductLabels(params?: {}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'product_labels/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                })
                .pipe(map((x) => {
                    return this.decryptResponse(x['raw'])
                })), false).pipe(take(1))
    }

    public addProductLabel(productLabel: any): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'product_labels/',
                this.camelCaseModelToSnakeCaseJson(productLabel),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public updateProductLabel(labelId: any, label: ProductLabelModel): Observable<ResponseModel<ProductLabelModel>> {
        return this.mapRequest(
            this._http.patch(this.url + 'product_labels/' + labelId + '/',
                this.camelCaseModelToSnakeCaseJson(label),
                FapAPIRequestOptions.withTokenRequestOptions),
                ProductLabelModel,
            false
        );
    }

    public deleteProductLabel(labelId: number): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.delete(this.url + 'product_labels/' + labelId + '/',
            FapAPIRequestOptions.withTokenRequestOptions));
    }

    public getIngredients(params?: any): Observable<ResponseModel<IngredientModel[]>> {
        return this.mapRequest<IngredientModel[]>(
            this._http.get(
                this.url + 'ingredients/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                false
            ).pipe(take(1));
        }

    public getIngredient(ingredientId: any): Observable<ResponseModel<IngredientModel[]>> {
        return this.mapRequest<IngredientModel[]>(
            this._http.get(
                this.url + 'ingredients/?list=' + ingredientId, {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                }),
            false
        ).pipe(take(1));
    }

    public createIngredient(field: IngredientInterface): Observable<ResponseModel<IngredientModel>> {
        return this.mapRequest<IngredientModel>(
            this._http.post(this.url + 'ingredients/',
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            IngredientModel,
            false
        ).pipe(take(1));
    }

    public updateIngredient(ingredientId: number, field: IngredientInterface): Observable<ResponseModel<IngredientModel>> {
        return this.mapRequest<IngredientModel>(
            this._http.patch(this.url + 'ingredients/' + ingredientId + '/',
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            IngredientModel,
            false
        ).pipe(take(1));
    }

    public deleteIngredient(ingredientId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + 'ingredients/' + ingredientId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public getOffers(params?: {}): Observable<ResponseModel<[]>> {
        return this.mapRequest<[]>(
            this._http.get(
                this.url + 'offer/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            false
        ).pipe(take(1));
    }

    public updateEInvoice(documentId: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.put(this.url + 'e-invoice/' + documentId + '/',
            {},
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public getProductUsage(): Observable<ResponseModel<TreatmentModel[]>> {
        return this.mapRequest<TreatmentModel[]>(
            this._http.get(this.url + 'product_usages/',
                FapAPIRequestOptions.withTokenRequestOptions),
                TreatmentModel,
            true
        ).pipe(take(1));
    }

    public createProductUsage(treatment: any): Observable<ResponseModel<TreatmentModel>> {
        return this.mapRequest<TreatmentModel>(
            this._http.post(this.url + 'product_usages/',
                this.camelCaseModelToSnakeCaseJson(treatment),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false
        ).pipe(take(1));
    }

    public updateProductUsage(treatmentId: number, treatment: any): Observable<ResponseModel<TreatmentModel>> {
        return this.mapRequest<TreatmentModel>(
            this._http.patch(this.url + 'product_usages/' + treatmentId + '/',
                this.camelCaseModelToSnakeCaseJson(treatment),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            TreatmentModel,
            false
        ).pipe(take(1));
    }

    public deleteProductUsage(treatmentId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'product_usages/' + treatmentId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }
}
