import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fap-info-section',
  templateUrl: './fap-info-section.component.html',
  styleUrls: ['./fap-info-section.component.scss']
})
export class FapInfoSectionComponent {

  @Input() description = null;
  @Input() langString;
  @Input() nameT;
  @Input() bodyT;
  @Input() isEditable: boolean = false;

  @Output()
  public editDescription: EventEmitter<any> = new EventEmitter();
  @Output()
  public deleteDescription: EventEmitter<any> = new EventEmitter();

  public subscriptions: Array<Subscription> = [];

  constructor(private sanitizer: DomSanitizer, public globalRegistry: GlobalRegistryService ) {}

  getHtmlContent(html) {
    if(!html) {
      return
    }
    let sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(html);
    if(sanitizedHtml) {
      return sanitizedHtml
    } else {
      return ''
    }
  }


}
