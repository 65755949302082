import { FapBaseModel } from '../../base/fap-base-model';
export class WidgetModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'content_type',
        'object_id',
        'type',
        'config',
        'view',
        'settings',
        'name',
        'name_t',
        'locked',
        'bg_color',
        'fg_color',
        'size_small',
        'size_medium',
        'size_large',
        'order'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = { };

    public id: number;
    public contentType: string;
    public objectId: number;
    public type: string;
    public view: any;
    public config: {};
    public settings: {};
    public name: string;
    public nameT: string;
    public locked: boolean;
    public bgColor: string;
    public fgColor: string;
    public sizeSmall: string;
    public sizeMedium: string;
    public sizeLarge: string;
    public order: any;
}
