import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../services/nav.service';
import { TypesService } from '../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { FapModalComponent } from '../../partials';
import { ActivityTypeModel } from '../../../core/models/activity/activity-type.model';
import { AttributeService } from '../../../core/services/api/attribute/attribute.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fap-activities-list-widget',
  templateUrl: './activities-list-widget.component.html',
  styleUrls: ['./activities-list-widget.component.scss']
})
export class FapActivitiesListComponent implements OnInit, OnChanges {
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() activities: any[] = [];
  @Input() persons: any[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() activityTypes: ActivityTypeModel[] = [];
  public subscriptions: Array<Subscription> = [];
  public mediaUrl = environment.mediaUrl;
  public translatedNames: any = [];
  public langString: string;
  public toggleMain = false;
  public toggleSub = false;
  public entityInfo: any = null;
  public formTypeId: number = null;
  @Output() public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public emitScroll: EventEmitter<any> = new EventEmitter<any>();
  @Output() public refreshActivitiesList: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('workflowModal') workflowModal: FapModalComponent;
  public selectedWidget;
  public html: any = null;
  public workflow: any;
  public objectId: number;
  public workflowId: number;
  public currentStep = 0;
  public totalSteps = 0
  public nextFormClick = false;
  public previousFormClick = false;
  public workflowTypeId = null;
  public errorFields: any[] =[];
  public person: any;
  public defaultSize = 'pc';
  public isLoading: boolean = false;

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, public typesService: TypesService, public cacheService: CacheResolverService, private attributeService: AttributeService, public translate: TranslateService, private toastr: ToastrService) { 
  }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.updateDefaultSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateDefaultSize();
  }

  private updateDefaultSize() {
    const width = window.innerWidth;
    this.defaultSize = width >= 1025 ? 'pc' : (width >= 768 ? 'tablet' : 'mobile');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'activities') && this.activities) {
      console.log(this.activities);
    }
  }

  hideMain() {
    this.toggleMain = false;
}

toggleMenu() {
    this.toggleMain = !this.toggleMain;
}

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation;
    }
}

onScroll(event: any) {
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    this.emitScroll.emit(true);
    console.log('scrolled')
  }
}

public getFormTypes() {
  
  this.typesService.getFormTypeById(this.formTypeId).subscribe((data) => {
    console.log(data);
      this.entityInfo = data.body.results;
  });
}

checkWorkflowPresence(activity): boolean {
  let bool = false;
  this.activityTypes.forEach(element => {
    if(activity.type === element.id) {
      if(element.entityWorkflow) {
        bool = true;
      } else {
        if(activity.assignedTo) {
          this.getUser(activity.assignedTo);
        }
      }
    }
  });
  return bool
}

getUser(id) {
  this.persons.forEach(person => {
    if(person.id === id) {
      this.person = person;
    }
  })
}

getActivityForm(id, activityId) {
  this.activityTypes.forEach(element => {
    if(id === element.id) {
      if(element.entityWorkflow) {
        this.workflowTypeId = element.entityWorkflow;
        this.newWorkflow(this.workflowTypeId, activityId);
      }
    }
  });
}

hidePoolsModal() {
  console.log('Hide pools modal');
}

onUpdateAction() {
  console.log('update action called');
}

closeModal() {
  this.html = null;
  this.workflow = null;
  this.workflowModal.hideModal();
}

goToPreviousForm() {
  this.isLoading = true;
  this.attributeService.getPreviousForm(this.workflow.id).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    this.previousFormClick = false;
    if(data.body.results.object_id) {
      setTimeout(() => {
        this.isLoading = false;
      }, 300)
    }
  }, (error) => {
    if(error.error.results.error.form && error.error.results.error.form.error) {
    this.toastr.error(error.error.results.error.form.error);
    this.errorFields = error.error.results.error.form.fields
    }
    if(error.error.results.error && !error.error.results.error.form) {
      this.toastr.error(error.error.results.error);
    }
    this.isLoading = false;
  })
}

goToNextForm() {
  this.isLoading = true;
  this.attributeService.getNextForm(this.workflow.id).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    if(this.currentStep > this.totalSteps) {
      this.toastr.success(this.translate.instant('workflow.workflowUpdatedSuccessfully'));
      this.currentStep = 0
    }
    this.nextFormClick = false;
    if(data.body.results.object_id) {
      setTimeout(() => {
        this.isLoading = false;
      }, 300)
    }
  }, (error) => {
    if(error.error.results.error.form && error.error.results.error.form.error) {
      this.toastr.error(error.error.results.error.form.error);
      this.errorFields = error.error.results.error.form.fields
      }
      if(error.error.results.error && !error.error.results.error.form) {
        this.toastr.error(error.error.results.error);
      }
  })
}

getActivityTypeName(activityTypeId: number): string {
  let activityTypeName = '';
  this.activityTypes.forEach(type => {
    if(type.id === activityTypeId) {
      activityTypeName = this.getTranslation(type.nameT);
    }
  });
  return activityTypeName
}

  public getWorkflow(id: number) {
    this.workflowId = id;
    this.attributeService.getWorkflowById(id).subscribe(data => {
      this.workflow = data.body.results;
      this.objectId = this.workflow.object_id;
    this.workflowModal.showModal();
      if(this.workflow.current_form) {
        this.formTypeId = this.workflow.current_form.type;
        this.getFormTypes();
      }
      this.currentStep = this.workflow.step;
      this.totalSteps = this.workflow.steps_count;
      if(this.currentStep > this.totalSteps) {
        this.currentStep = 0;
      }
    })
  }

  public newWorkflow(id, activityId) {
    this.isLoading = true;
    const param = {
      type: id
    }
    this.attributeService.postActivityWorkflow(param, activityId).subscribe(data => {
      this.workflow = data.body.results;
      this.workflowId = data.body.results.id;
      this.objectId = this.workflow.object_id;
      this.workflowModal.showModal();
  
      if(this.workflow.current_form) {
        this.formTypeId = this.workflow.current_form.type;
        this.getFormTypes();
      }
      this.currentStep = this.workflow.step;
      this.totalSteps = this.workflow.steps_count;
      if(this.currentStep > this.totalSteps) {
        this.currentStep = 0;
      }
      if(data.body.results.object_id) {
        setTimeout(() => {
          this.isLoading = false;
        }, 300)
      }
    }, (error) => {
      this.isLoading = false;
      this.toastr.error(error.error.results.error);
    })
  }

  nextButtonClick(value) {
    if(this.currentStep === this.workflow.steps_count) {
      return
    } else {
      if(value) {
        this.goToNextForm();
      }
    }
  }
  
  prevButtonClick() {
    this.goToPreviousForm();
  }

  submitForm() {
    this.attributeService.submitWorkfow(this.workflowId).subscribe(data => {
      console.log(data);
      this.workflow = null;
      this.html = data.body.results;
      console.log(this.html);
      if(this.html.html === null) {
        this.closeModal();
      }
      this.refreshActivitiesList.emit();
    }, (err) => {
      this.toastr.error(err.error.results.error);
    });
  }

}
