<fap-header>
  <div class="d-flex filters align-items-center">
    <div class="tree_combo mr-3">
      <fap-tree-combo></fap-tree-combo>
    </div>
    <div class="add_btn mr-3">
      <fap-round-button [backgroundColor]="'tertiary'" (clicked)="navServices.openWidget.next(true)"></fap-round-button>
    </div>
    <fap-search-filter
      [isDateFilterEnabled]="true"
      [isDayFilterEnabled]="true"
      [filterLabel]="'Drill'"
      (filter)="drillData($event)"
    ></fap-search-filter>
  </div>
</fap-header>
<dashboard-layout
  (addWidget)="addWidget($event)"
  (updateWidget)="updateWidget($event)"
  (deleteWidget)="deleteWidget($event)"
  [lots]="lots"
  [notes]="notes"
  [objects]="objectTypes"
  [unitTypes]="unitTypes"
  [devices]="devices"
  [sensors]="sensors"
  [widgets]="filteredWidgets"
  [activityTypes]="activityTypes"
  style="max-width: 100%; width: 100%; margin: auto;"
></dashboard-layout>
